<template>
  <div class="outer">
    <!-- Wrapper -->
    <div
      class="psw-reset"
      @keyup.enter="changeCalib"
      v-if="this.tempCalibration"
    >
      <form id="psw-form" autocomplete="off">
        <div class="form-column c1">
          <!-- Gain X -->
          <label for="gain-x">Gain Asse X:</label>
          <input
            type="number"
            id="gain-x"
            name="gain-x"
            ref="gain-x"
            min="0"
            step="any"
            v-model.number="tempCalibration.gainX"
            autofocus
          />
          <!-- Gain X -->
          <label for="gain-y">Gain Asse Y:</label>
          <input
            type="number"
            id="gain-y"
            name="gain-y"
            ref="gain-y"
            min="0"
            step="any"
            v-model.number="tempCalibration.gainY"
            autofocus
          />
          <!-- Gain Y -->
          <label for="gain-z">Gain Asse Z:</label>
          <input
            type="number"
            id="gain-z"
            name="gain-z"
            ref="gain-z"
            min="0"
            step="any"
            v-model.number="tempCalibration.gainZ"
            autofocus
          />
        </div>
        <div class="form-column c2">
          <!-- Gain X -->
          <label for="offset-x">Offset Asse X:</label>
          <input
            type="number"
            id="offset-x"
            name="offset-x"
            ref="offset-x"
            min="0"
            step="any"
            v-model.number="tempCalibration.offsetX"
            autofocus
          />
          <!-- Gain X -->
          <label for="offset-y">Offset Asse Y:</label>
          <input
            type="number"
            id="offset-y"
            name="offset-y"
            ref="offset-y"
            min="0"
            step="any"
            v-model.number="tempCalibration.offsetY"
            autofocus
          />
          <!-- Gain Y -->
          <label for="offset-z">Offset Asse Z:</label>
          <input
            type="number"
            id="offset-z"
            name="offset-z"
            ref="offset-z"
            min="0"
            step="any"
            v-model.number="tempCalibration.offsetZ"
            autofocus
          />
        </div>
        <!-- Buttons -->
        <div class="btns">
          <button type="button" class="button" @click="undoChange">
            Annulla
          </button>
          <button type="button" class="button" @click="changeCalib">
            Conferma
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "calibration-edit",
  props: {
    calibration: Object,
  },
  data() {
    return {
      tempCalibration: null,
      defaultCalibration: {
        gainX: 1,
        gainY: 1,
        gainZ: 1,
        offsetX: 0,
        offsetY: 0,
        offsetZ: 0
      },
    };
  },
  methods: {
    changeCalib() {
      this.$emit("change-calibration", this.tempCalibration);
    },
    undoChange() {
      // Annulla la modifica
      this.$emit("undo");
    }
  },
  mounted() {
    // Se ho la calibrazione come prop inizializzo con quella
    if (this.calibration) {
      this.tempCalibration = JSON.parse(JSON.stringify(this.calibration));
    } else {
      // Altrimenti uso quella di default
      this.tempCalibration = JSON.parse(JSON.stringify(this.defaultCalibration));
    }
    // Appena apro il modale setto il focus sul campo principale
    setTimeout(() => {
      this.$refs["gain-x"].focus();
    }, 0);
  },
};
</script>
<style scoped>
.outer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.3);
}

.info {
  font-size: 1em;
  font-family: inherit;
  font-weight: 700;
  white-space: normal;
}

.errors {
  color: rgb(255, 0, 0);
}

.messages {
  color: green;
}

#psw-form {
  display: grid;
  grid-template-rows: 70% 30%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 50% 50%;
  height: 100%;
}

.form-column {
  padding: 15px;
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  grid-template-columns: 100%;
  font-size: 0.7em;
  white-space: nowrap;
  height: 100%;
}

label {
  font-weight: 700;
  color: rgb(150, 153, 162);
}
input {
  height: 80%;
  border-width: 0px;
  border-radius: 5px;
  outline-width: 0;
  padding-left: 10px;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}
.psw-reset {
  width: 680px;
  height: 300px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: solid 0.1em white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
}
.btns {
  grid-column: 1/3;
  height: 45%;
  margin: auto;
  padding-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.button {
  width: 40%;
  height: 100%;
  line-height: 100%;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(47, 112, 177);
  border: solid 2px white;
  border-radius: 7px;
  white-space: nowrap;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.valid-field {
  border: solid 1.2px green !important;
}
.invalid-field {
  border: solid 1.2px red !important;
}

/* Rimuove le frecce dagli input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
