<i18n>
{
  "en": {
    "editImage": "Edt Image",
    "editCaption": "Edit the image of the monitored structure on which to display the sensors.",
    "rotate": "Rotate",
    "flip": "Flip",
    "uploadImage": "Upload image",
    "preview": "Preview",
    "undo": "Undo",
    "apply": "Apply",
    "uploadImgFirst": "Upload the image first",
    "uploadWarning": "Are you sure you want to permanently change the structure image?"
  },
  "it": {
    "editImage": "Modifica Immagine",
    "editCaption": "Modifica l'immagine della struttura monitorata su cui sono posizionati i sensori.",
    "rotate": "Ruota",
    "flip": "Ribalta",
    "uploadImage": "Carica immagine",
    "preview": "Anteprima",
    "undo": "Annulla",
    "apply": "Applica",
    "uploadImgFirst": "Carica prima l'immagine",
    "uploadWarning": "Sei sicuro di voler cambiare l'immagine della struttura? Azione irreversibile."
  }
}
</i18n>

<template>
  <modal-template>
    <template v-slot:header>
      <div class="header">
        <h2>{{ $t("editImage") }}</h2>
      </div>
    </template>
    <template v-slot:content>
      <div id="content-wrapper" class="noselect-hard">
        <div id="vert-divider"></div>
        <input ref="input" type="file" name="image" accept="image/*" @change="setImage" />
        <section class="cropper-area" v-show="imgSrc">
          <p>{{ $t("editCaption") }}</p>
          <div class="img-cropper">
            <vue-cropper v-if="imgSrc" ref="cropper" :aspect-ratio="30 / 9" :src="imgSrc" @ready="readyModule" preview=".preview" style="height: 228px" />
          </div>
        </section>
        <div class="img-not-found" v-show="!imgSrc">
          <h2>{{ $t("uploadImgFirst") }}</h2>
        </div>
        <div class="actions">
          <button class="rounded-button" @click.prevent="rotate(90)"><img src="../../../public/assets/icons/rotate_left.svg" alt /> {{ $t("rotate") }} +90deg</button>
          <button class="rounded-button" @click.prevent="rotate(-90)"><img src="../../../public/assets/icons/rotate_right.svg" alt /> {{ $t("rotate") }} -90deg</button>
          <button class="rounded-button" ref="flipX" @click.prevent="flipX"><img src="../../../public/assets/icons/flip_horiz.svg" alt /> {{ $t("flip") }} X</button>
          <button class="rounded-button" ref="flipY" @click.prevent="flipY"><img src="../../../public/assets/icons/flip_vert.svg" alt /> {{ $t("flip") }} Y</button>
          <button class="rounded-button" @click.prevent="reset"><img src="../../../public/assets/icons/reset.svg" alt /> Reset</button>
        </div>
        <section class="preview-area">
          <p>{{ $t("preview") }}</p>
          <div v-if="imgSrc" class="preview" />
          <div v-else class="img-not-found" style="height: 54px"></div>
          <button class="rounded-button border-white" @click.prevent="showFileChooser">{{ $t("uploadImage") }}</button>
          <p class="info-text">Max file size: 2MB</p>
        </section>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="std-button footer-btn" @click="closeMe">{{ $t("undo") }}</button>
      <button type="button" class="std-button footer-btn save-btn" @click="cropImage">{{ $t("apply") }}</button>
    </template>
  </modal-template>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

import modalTemplate from "./ModalTemplate.vue";
export default {
  name: "ModalImgUpload",
  components: {
    modalTemplate,
    VueCropper,
  },
  props: {
    exsistingImg: String,
  },
  data() {
    return {
      imgSrc: null,
      cropImg: "",
      data: null,
    };
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    readyModule() {
      const imgData = this.$refs.cropper.getData();
      this.$refs.cropper.setCropBoxData(imgData);
    },
    cropImage() {
      if (confirm(this.$t("uploadWarning"))) {
        // get image data for post processing, e.g. upload or setting image src
        this.cropImg = this.$refs.cropper
          .getCroppedCanvas({
            width: 1920,
            height: 822,
            viewMode: 2,
          })
          .toDataURL("image/jpeg", 1);
        this.$emit("setNewImage", this.cropImg);
        this.closeMe();
      }
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      console.log("SetCrBData");
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      console.log("SetData");
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      console.log(file.size);
      if (file.size / 1024 / 1024 > 2) {
        alert("Max file size: 2 MB");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  mounted() {
    if (this.exsistingImg) {
      this.imgSrc = this.exsistingImg;
    } else {
      //this.imgSrc = require("../../../public/assets/BridgeChop.png");
      this.imgSrc = null;
    }
  },
  watch: {
    exsistingImg: function(newValue) {
      if (newValue) {
        this.imgSrc = this.exsistingImg;
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 8px 0;
  padding: 0;
  font-size: 14px;
  text-align: left;
}
input[type="file"] {
  display: none;
}
button {
  padding: 6px 16px;
  min-width: 100px;
}
#content-wrapper {
  width: 1000px;
  display: grid;
  grid-template-columns: 10fr auto 200px;
}
#vert-divider {
  grid-column: 2;
  grid-row: 1/3;
  height: 100%;
  width: 2px;
  background-color: white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.header h2 {
  margin: 0;
  padding: 0;
}
.header a {
  text-decoration: none;
  color: black;
}
.content {
  display: flex;
  justify-content: center;
}
.cropper-area {
  width: 800px;
  grid-column: 1;
  padding: 20px;
  box-sizing: border-box;
}
.actions {
  margin: 4px 20px 24px 20px;
  display: flex;
  justify-content: space-between;
  grid-column: 1;
  grid-row: 2;
}
textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 100%;
  grid-column: 3;
  padding: 60px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.preview {
  width: 100%;
  height: 54px;
  overflow: hidden;
  margin-bottom: 16px;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
.img-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 20px;
  margin-top: 40px;
}
.info-text {
  font-style: italic;
}
img {
  max-width: 100%; /* This rule is very important, please do not ignore this! */
}
.footer-btn {
  margin-right: 20px;
}
.save-btn {
  background-color: rgb(21, 146, 230);
}
.border-white {
  border: solid 2px white;
}
</style>
