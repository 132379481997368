<template>
  <div>
    <SmallLoadingLogo class="small-logo" v-if="getIsLoading" />
    <list-groups-v-2
      v-if="view === views.listGroups"
      @add-group="onAddGroup"
      @edit-group="onClickEditGroup"
      @close-groups="onCloseGroups"
    />
    <edit-groups-v-2
      v-else-if="view === views.editGroup"
      :sensorType="sensorType"
      :structure="structure"
      :mode="mode"
      @back="onClickBack"
      @save="onClickSave"
      @close-groups="onCloseGroups"
    />
  </div>
</template>

<script>
import EditGroupsV2 from "./EditGroupsV2.vue";
import ListGroupsV2 from "./ListGroupsV2.vue";
import SmallLoadingLogo from "../../../components/ui/SmallLoadingLogo.vue";
import { mapGetters } from "vuex";

export default {
  name: "Groups",
  components: { ListGroupsV2, EditGroupsV2, SmallLoadingLogo },
  props: {
    structure: Object,
    sensorType: String
  },
  data() {
    return {
      views: {
        listGroups: "LIST_GROUPS",
        editGroup: "EDIT_GROUP"
      },
      view: null,
      mode: {
        editing: false,
        group: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      getIsLoading: "group/getIsLoading",
    }),
  },
  methods: {
    onCloseGroups() {
      this.$emit("close-groups");
    },
    onAddGroup() {
      this.mode.editing = false;
      this.mode.group = {};
      this.view = this.views.editGroup;
    },
    onClickEditGroup(group) {
      this.view = this.views.editGroup;
      this.mode.editing = true;
      this.mode.group = group;
    },
    onClickBack() {
      this.mode.editing = false;
      this.mode.group = {};
      this.view = this.views.listGroups;
    },
    onClickSave() {
      this.mode.editing = false;
      this.mode.group = {};
      this.view = this.views.listGroups;
    }
  },

  created() {
    this.view = this.views.listGroups;
  }
};
</script>

<style scoped>

.small-logo{
  opacity: 1;
  background: rgba(0,0,0,0.7);
  direction: flex;
  z-index: 20;
  position: fixed;
}
</style>
