<i18n>
{
  "en": {
      "title": "View the entire system on the monitored structure",
      "subTitle": "In this section you can view the real monitored structure with its sensors installed in their real position:",
      "li1": "Uploading a photo of the structure allows you to correctly position the sensors as in reality",
      "li2": "Viewing the exact position of the sensors helps to understand the correspondence of the detected data",
      "li3": "Quickly understand the operating status of the entire sensor system thanks to the colour of the icons",
      "li4": "Choosing which types of sensors to show improves selection and display",
      "li5": "Expanding or minimizing the sensor icon allows you not to view overlapping sensors"

  },
  "it": {
      "title": "Visualizza l’intero sistema sulla struttura monitorata",
      "subTitle": "In questa sezione puoi visualizzare la reale struttura monitorata con i relativi sensori installati nella loro reale posizione:",
      "li1": "Caricare una foto della struttura ti consente di posizionare correttamente i sensori come nella realtà",
      "li2": "Visualizzare l’esatta ubicazione dei sensori aiuta a capire la corrispondenza dei dati rilevati",
      "li3": "Comprendi velocemente lo stato di funzionamento dell’intero sistema di sensori grazie al colore dell’icona",
      "li4": "Scegliere quali tipologie di sensori mostrare facilita la selezione e la visualizzazione",
      "li5": "Espandere o ridurre l’icona dei sensori consente di non visualizzare i sensori sovrapposti"
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
      <ul>
        <li>{{ $t("li1") }}</li>
        <li>{{ $t("li2") }}</li>
        <li>{{ $t("li3") }}</li>
        <li>{{ $t("li4") }}</li>
        <li>{{ $t("li5") }}</li>
      </ul>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../BallonTemplate.vue";

export default {
  name: "StructBallon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 500px;
}
li{
  margin: 6px 0;
}
</style>
