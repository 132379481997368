<template>
  <div class="outer-wrap" v-if="this.sensorTemp.userConfig" @click="closeMe">
    <div class="sensor-info" @click.stop>
      <!-- Header -->
      <div id="sensor-header" class="noselect c1">
        <h1>{{ !sensor ? "Aggiungi Sensore" : "Modifica Sensore" }}</h1>
        <a @click="closeMe" class="close"></a>
      </div>
      <!-- Wrapper Form -->
      <div class="form-wrap c1">
        <!-- Form Header -->
        <div class="form-header">
          <h5>Dati sensore</h5>
        </div>
        <!-- Tipo -->
        <label for="type">Tipo:</label>
        <select
          id="type"
          v-model="sensorTemp.type"
          class="select-options"
          :disabled="disabledSensorTypeSelect"
        >
          <option
            :value="type"
            v-for="(type, index) in sensorTypes"
            :key="index"
            >{{ type }}</option
          >
        </select>
        <!-- EUI -->
        <label for="user">EUI:</label>
        <input
          type="text"
          id="user"
          name="user"
          ref="username"
          oninput="this.value = this.value.toUpperCase()"
          :class="[
            validateSensorEUI(sensorTemp.eui) ? 'valid-field' : 'invalid-field'
          ]"
          v-model.trim="sensorTemp.eui"
          spellcheck="false"
          :disabled="!editing"
        />
        <!-- Nome -->
        <label for="APPID">Nome:</label>
        <input
          type="text"
          id="loraappid"
          name="APPID"
          :class="[
            validateSensorName(sensorTemp.userConfig.name)
              ? 'valid-field'
              : 'invalid-field'
          ]"
          v-model="sensorTemp.userConfig.name"
          spellcheck="false"
          :disabled="!editing"
        />
        <!-- Disposizione -->
        <label for="disp" v-if="sensorTemp.type === 'deck'">
          Disposizione:</label
        >
        <select
          id="disp"
          v-if="sensorTemp.type === 'deck'"
          v-model="sensorTemp.orientation"
          class="select-options"
        >
          <option
            :value="or"
            v-for="(or, index) in ['verticale', 'orizzontale']"
            :key="index"
            >{{ or }}</option
          >
        </select>
        <!-- BeamFormat -->
        <label v-if="sensorTemp.type === 'tiltmeter'" for="beam-format"
          >Beam:</label
        >
        <input
          v-if="sensorTemp.type === 'tiltmeter'"
          type="checkbox"
          id="beam-format"
          v-model="sensorTemp.beamFormat"
          :disabled="sensorTemp.type != 'tiltmeter'"
        />
        <!-- Revision -->
        <label for="rev">Versione:</label>
        <select id="rev" v-model="sensorTemp.rev" class="select-options" disabled>
          <option
            :value="rev"
            v-for="(rev, index) in sensorRevs[sensorTemp.type]"
            :key="index"
            >{{ rev }}</option
          >
        </select>
      </div>
      <!-- Settings -->
      <div class="form-wrap c2">
        <div class="form-header">
          <h5>Settings</h5>
        </div>
        <!-- Soglia di Attivazione -->
        <label
          v-if="getAvailableOptionsByField['mesUnits']['activationThreshold']"
          for="activationTh"
          >Attivazione [{{
            getAvailableOptionsByField["mesUnits"]["activationThreshold"]
          }}] :</label
        >
        <input
          v-if="getAvailableOptionsByField['mesUnits']['activationThreshold']"
          v-model.number="sensorTemp.userConfig.activationThreshold"
          type="number"
          id="activationTh"
          :class="[
            validateActivationTh(
              sensorTemp,
              sensorTemp.userConfig.activationThreshold
            )
              ? 'valid-field'
              : 'invalid-field'
          ]"
          spellcheck="false"
        />
        <!-- Soglia di Allarme -->
        <label
          v-if="getAvailableOptionsByField['mesUnits']['alert']"
          for="alert"
          >Allarme [{{ getAvailableOptionsByField["mesUnits"]["alert"] }}]
          :</label
        >
        <input
          v-if="getAvailableOptionsByField['mesUnits']['alert']"
          :disabled="!editing"
          v-model.number="sensorTemp.userConfig.threshold"
          :class="[
            validateAlertThresh(sensorTemp, sensorTemp.userConfig.threshold)
              ? 'valid-field'
              : 'invalid-field'
          ]"
          type="number"
          id="alert"
        />
        <!-- SR -->
        <label
          v-if="getAvailableOptionsByField['options']['sampleRate']"
          for="samplerate"
          >Sample Rate:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['sampleRate']"
          id="samplerate"
          v-model.number="sensorTemp.userConfig.sampleRate"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['sampleRate']"
        >
          <option
            :value="sr"
            v-for="(sr, index) in getAvailableOptionsByField['options'][
              'sampleRate'
            ]"
            :key="index"
          >
            {{
              sr + " " + getAvailableOptionsByField["mesUnits"]["sampleRate"]
            }}
          </option>
        </select>
        <!-- Channel Number -->
        <label
          v-if="getAvailableOptionsByField['options']['channelNumber']"
          for="channelnumber"
          >Channel Number:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['channelNumber']"
          id="channelnumber"
          v-model.number="sensorTemp.userConfig.channelNumber"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['channelNumber']"
        >
          <option
            :value="sr"
            v-for="(sr, index) in getAvailableOptionsByField['options'][
              'channelNumber'
            ]"
            :key="index"
          >
            {{ sr }}
          </option>
        </select>
        <!-- Risoluzione -->
        <label
          v-if="getAvailableOptionsByField['options']['resolution']"
          for="resolution"
          >Risoluzione:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['resolution']"
          id="resolution"
          v-model.number="sensorTemp.userConfig.resolution"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['resolution']"
        >
          <option
            :value="type"
            v-for="(type, index) in getAvailableOptionsByField['options'][
              'resolution'
            ]"
            :key="index"
          >
            {{
              type + " " + getAvailableOptionsByField["mesUnits"]["resolution"]
            }}
          </option>
        </select>
        <!-- Tipo acquisizione -->
        <label
          v-if="getAvailableOptionsByField['options']['typeAcqu']"
          for="typeAcqu"
          >Tipo acquisizione:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['typeAcqu']"
          id="typeAcqu"
          v-model.number="sensorTemp.userConfig.typeAcqu"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['typeAcqu']"
        >
          <option
            :value="type"
            v-for="(type, index) in getAvailableOptionsByField['options'][
              'typeAcqu'
            ]"
            :key="index"
          >
            {{ getAvailableOptionsByField["stringValues"]["typeAcqu"][index] }}
          </option>
        </select>
        <!-- Config assi -->
        <label
          v-if="getAvailableOptionsByField['options']['confXYZ']"
          for="resolution"
          >Config assi:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['confXYZ']"
          id="confXYZ"
          v-model.number="sensorTemp.userConfig.confXYZ"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['confXYZ']"
        >
          <option
            :value="type"
            v-for="(type, index) in getAvailableOptionsByField['options'][
              'confXYZ'
            ]"
            :key="index"
          >
            {{ getAvailableOptionsByField["stringValues"]["confXYZ"][index] }}
          </option>
        </select>
        <!-- TypeSync -->
        <label
          v-if="getAvailableOptionsByField['options']['typeSync']"
          for="resolution"
          >typeSync:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['typeSync']"
          id="confXYZ"
          v-model.number="sensorTemp.userConfig.typeSync"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['typeSync']"
        >
          <option
            :value="type"
            v-for="(type, index) in getAvailableOptionsByField['options'][
              'typeSync'
            ]"
            :key="index"
          >
            {{ type }}
          </option>
        </select>
        <!-- Time switch -->
        <label
          v-if="getAvailableOptionsByField['options']['timeSwitch']"
          for="timeSwitch"
          >Time switch:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['timeSwitch']"
          id="timeSwitch"
          v-model.number="sensorTemp.userConfig.timeSwitch"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['timeSwitch']"
        >
          <option
            :value="value"
            v-for="(value, index) in getAvailableOptionsByField['options'][
              'timeSwitch'
            ]"
            :key="index"
          >
            {{
              value + " " + getAvailableOptionsByField["mesUnits"]["timeSwitch"]
            }}
          </option>
        </select>
        <!-- Length switch -->
        <label
          v-if="getAvailableOptionsByField['options']['lengthSample']"
          for="lengthSample"
          >lengthSample:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['lengthSample']"
          id="lengthSample"
          v-model.number="sensorTemp.userConfig.lengthSample"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['lengthSample']"
        >
          <option
            :value="value"
            v-for="(value, index) in getAvailableOptionsByField['options'][
              'lengthSample'
            ]"
            :key="index"
          >
            {{
              value +
                " " +
                getAvailableOptionsByField["mesUnits"]["lengthSample"]
            }}
          </option>
        </select>
        <!-- fSample -->
        <label
          v-if="getAvailableOptionsByField['options']['fSample']"
          for="fSample"
          >fSample:</label
        >
        <select
          v-if="getAvailableOptionsByField['options']['fSample']"
          id="fSample"
          v-model.number="sensorTemp.userConfig.fSample"
          class="select-options"
          :disabled="!getAvailableOptionsByField['options']['fSample']"
        >
          <option
            :value="value"
            v-for="(value, index) in getAvailableOptionsByField['options'][
              'fSample'
            ]"
            :key="index"
          >
            {{
              value + " " + getAvailableOptionsByField["mesUnits"]["fSample"]
            }}
          </option>
        </select>
        <!-- offsetClassC -->
        <label
          v-if="
            getDefaultUserConfig(sensorTemp.type, sensorTemp.rev)[
              'offsetClassC'
            ] != undefined
          "
          for="offsetClassC"
          >offsetClassC [min.]:</label
        >
        <input
          v-if="
            getDefaultUserConfig(sensorTemp.type, sensorTemp.rev)[
              'offsetClassC'
            ] != undefined
          "
          :class="[
            validateOffsetClassC(sensorTemp, sensorTemp.userConfig.offsetClassC)
              ? 'valid-field'
              : 'invalid-field'
          ]"
          :disabled="!editing"
          v-model.number="sensorTemp.userConfig.offsetClassC"
          type="number"
          id="offsetClassC"
          max="59"
        />
        <!-- Sub multicast -->
        <label
          v-if="
            getDefaultUserConfig(sensorTemp.type, sensorTemp.rev)[
              'subMulticast'
            ] != undefined
          "
          for="subMulticast"
          >Sub multicast:</label
        >
        <input
          v-if="
            getDefaultUserConfig(sensorTemp.type, sensorTemp.rev)[
              'subMulticast'
            ] != undefined
          "
          :class="[
            validateSubMulticast(sensorTemp, sensorTemp.userConfig.subMulticast)
              ? 'valid-field'
              : 'invalid-field'
          ]"
          :disabled="!editing"
          v-model.number="sensorTemp.userConfig.subMulticast"
          type="number"
          id="subMulticast"
          spellcheck="false"
        />
        <!-- Calibrazione -->
        <label
          v-if="getAvailableOptionsByField['options']['calibration']"
          for="psw-reset"
          >Calibrazione:</label
        >
        <transition name="settings">
          <Calibration
            v-if="calibrationEditing"
            :calibration="sensorTemp.calibration"
            @change-calibration="changeCalibration"
            @undo="closeCalib"
          />
        </transition>
        <button
          v-if="getAvailableOptionsByField['options']['calibration']"
          class="button embed-btn"
          id="calibration"
          type="button"
          @click="calibrationEdit"
          :class="[
            this.sensor || sensorTemp.calibration
              ? 'valid-field'
              : 'invalid-field'
          ]"
        >
          {{ sensorTemp.calibration ? "Modifica" : "Inserisci" }}
        </button>
      </div>
      <!-- Footer -->
      <div id="stats" class="form-wrap">
        <div class="form-header">
          <h5>Statistiche</h5>
        </div>
        <div class="stats-container">
          <p>
            Pacchetti Previsti:
            <span class="strong-text">{{
              sensorTemp.stats
                ? sensorTemp.stats.expectedPackets || "N.D."
                : "N.D."
            }}</span>
          </p>
          <p>
            Pacchetti Persi:
            <span class="strong-text">{{
              sensorTemp.stats ? sensorTemp.stats.lostPackets || "N.D." : "N.D."
            }}</span>
          </p>
          <p>
            Packet Loss Ratio:
            <span class="strong-text">{{
              sensorTemp.stats && sensorTemp.stats.expectedPackets > 1
                ? (
                    sensorTemp.stats.lostPackets /
                    sensorTemp.stats.expectedPackets
                  ).toFixed(3)
                : "N.D."
            }}</span>
          </p>
          <button
            @click="resetSensorStats"
            class="button embed-btn"
            id="reset-stats"
            type="button"
            :disabled="
              !sensorTemp.stats || sensorTemp.stats.expectedPackets == 0
            "
          >
            RESET
          </button>
        </div>
      </div>
      <div id="footer">
        <div class="footer-btn">
          <button
            v-if="this.sensor"
            type="button"
            class="button c1"
            @click="deleteSensor"
          >
            Elimina Sensore
          </button>
          <button type="button" class="button c4" @click="closeMe">
            {{ $t("message.undo") }}
          </button>
          <button
            type="button"
            class="button save c5"
            :disabled="!validForm"
            @click="saveSettings"
          >
            {{ $t("message.saveChanges") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  validateEUI,
  validateSensorName,
  validateActivationTh,
  validateOffsetClassC,
  validateSubMulticast,
  validateThreshold
} from "../../../helpers/validate";
import Calibration from "./Calibration.vue";

export default {
  name: "sensor-info",
  components: {
    Calibration
  },
  props: {
    sensor: Object, // Quando è presente considero il tutto come un 'modifica sensore',
    newPosition: Object, // Quando inserisco un nuovo sensore gli do la posizione
    newType: String,
    generic: Boolean,
    sensors: Array,
    disabledSensorTypeSelect: Boolean
  },
  data() {
    return {
      sensorTemp: {},
      editing: true,
      calibrationEditing: false,
      sensorTypes: [
        "deck",
        "tiltmeter",
        "accelerometer",
        "crackmeter",
        "analog-node",
        "vibrometer"
      ],
      sensorRevs: {
        deck: ["1.0", "2.0"],
        tiltmeter: ["1.0", "2.0", "3.0"],
        accelerometer: ["1.0", "2.0", "3.0", "4.0"],
        "analog-node": ["1.0", "2.0"],
        crackmeter: ["1.0", "2.0"],
        vibrometer: ["1.0"]
      }
    };
  },
  methods: {
    validateSensorEUI(eui) {
      // Valido se EUI ok e -> sto aggiungendo un nuovo sensore che non esiste OR sto modificandonene uno.
      return (
        validateEUI(eui) &&
        (!this.sensors.some(sn => sn.eui === eui) || this.sensor)
      );
    },
    validateSensorName: validateSensorName,
    validateActivationTh: validateActivationTh,
    validateOffsetClassC: validateOffsetClassC,
    validateSubMulticast: validateSubMulticast,
    validateAlertThresh: validateThreshold,
    // Ritorna i valori dei base dell'oggetto sensore
    // eslint-disable-next-line no-unused-vars
    getDefaultRootData(sensorType, sensorRevision) {
      let defaultRootData = {};
      switch (sensorType) {
        // Deck
        case "deck":
          defaultRootData = {
            orientation: "orizzontale"
          };
          break;
        default:
          return {};
      }
      return defaultRootData;
    },
    // Ritorna la userConfig di default di un sensore dato il tipo e la revision
    getDefaultUserConfig(sensorType, sensorRevision) {
      let defaultUserConfig;
      switch (sensorType) {
        // Vibrometer
        case "vibrometer":
          defaultUserConfig = {
            workingThreshold: 50,
            sampleRate: 2,
            typeAcqu: "time_triggered",
            color: "purple",
            threshold: {
              normativeName: "custom",
              monitoringTypeName: null,
              buildingCategoryName: null,
              vibrationsTypeName: null,
              customAxes: ["x", "y", "z"],
              customThreshold: 12,
              customOperation: "sqrt"
            }
          };
          break;
        // Crackmeter
        case "crackmeter":
          defaultUserConfig = {
            threshold: 12,
            activationThreshold: null,
            color: "black"
          };
          break;
        // Nodi digitale
        case "analog-node":
          defaultUserConfig = {
            color: "red",
            warmupTime: 12,
            acquisitionTime: 12,
            activationThreshold: null,
            channels: [
              {
                enabled: false,
                type: "4-20",
                name: "CH.1"
              },
              {
                enabled: false,
                type: "4-20",
                name: "CH.2"
              },
              {
                enabled: false,
                type: "4-20",
                name: "CH.3"
              },
              {
                enabled: false,
                type: "4-20",
                name: "CH.4"
              }
            ]
          };
          break;
        //Inclinometro
        case "tiltmeter":
          defaultUserConfig = {
            color: "green",
            activationThreshold: null,
            sampleRate: 2
            //threshold: 36,
          };
          break;
        // Deck
        case "deck":
          defaultUserConfig = {
            color: "blue",
            activationThreshold: 0.144,
            resolution: 0.012,
            threshold: 6
          };
          break;
        // Accelerometro
        case "accelerometer":
          // V4
          if (sensorRevision === "4.0") {
            defaultUserConfig = {
              lengthSample: 8192,
              color: "red",
              timeSwitch: 6,
              typeAcqu: 2,
              fSample: 80,
              offsetClassC: 0,
              confXYZ: 0,
              subMulticast: 0,
              activationThreshold: 50,
              resolution: 0.03125,
              typeSync: 1,
              channelNumber: 8,
              threshold: 256 // Soglia di allarme
            };
          }
          // V3
          else if (sensorRevision === "3.0") {
            defaultUserConfig = {
              lengthSample: 8192,
              color: "red",
              timeSwitch: 6,
              typeAcqu: 2,
              fSample: 80,
              offsetClassC: 0,
              confXYZ: 0,
              subMulticast: 0,
              activationThreshold: 50,
              resolution: 0.03125,
              typeSync: 1,
              threshold: 256 // Soglia di allarme
            };
          } else {
            defaultUserConfig = {
              color: "red",
              activationThreshold: 3.125,
              resolution: 0.25,
              sampleRate: 12,
              threshold: 12
            };
          }
          break;
      }
      return defaultUserConfig;
    },
    /* Carica i valori di default in base al tipo di sensore */
    loadDefaultValues(type, revision, prevRevision = {}) {
      // Prendo l'ultima revision disponibile per il relativo sensorType
      const sensorType = type || this.sensorTemp.type;
      const lastRevision =
        revision ||
        this.sensorRevs[sensorType][this.sensorRevs[sensorType].length - 1];
      console.log("Last rev", lastRevision);
      this.$set(this.sensorTemp, "rev", lastRevision);
      const defaultUserConfig = this.getDefaultUserConfig(
        sensorType,
        lastRevision
      );
      console.log("defaultUserConfig", defaultUserConfig);
      const defaultRootData = this.getDefaultRootData(sensorType, lastRevision);
      // Elimino campi che non ho nella revision scelta
      for (let key of Object.keys(
        this.getDefaultRootData(sensorType, prevRevision)
      )) {
        delete this.sensorTemp[key];
      }
      // Carico valori root
      for (let key of Object.keys(defaultRootData)) {
        //console.log(key + " -> " + defaultRootData[key]);
        this.$set(this.sensorTemp, key, defaultRootData[key]);
      }
      // Carico userConfig
      this.$set(this.sensorTemp, "userConfig", {});
      for (let key of Object.keys(defaultUserConfig)) {
        console.log(key + " -> " + defaultUserConfig[key]);
        this.$set(this.sensorTemp.userConfig, key, defaultUserConfig[key]);
      }
    },
    saveSettings() {
      if (this.sensor) {
        // Sto modificando un sensore esistente
        this.updateSensor();
      } else {
        // Sto inserendo un nuovo sensore
        this.insertNewSensor();
      }
    },
    deleteSensor() {
      if (
        confirm(
          "Eliminare il sensore ed i relativi dati? Azione irreversibile!"
        )
      ) {
        this.$emit("delete-sensor", this.sensor.eui);
        this.closeMe();
      }
    },
    updateSensor() {
      delete this.sensorTemp.stats; // non voglio inviare stats al server
      delete this.sensorTemp.groups; // non voglio inviare groups al server
      delete this.sensorTemp.lastTX; // non voglio inviare stats al server
      delete this.sensorTemp.signalQuality; // non voglio inviare signalQuality al server
      this.$emit("update-sensor", this.sensor.eui, this.sensorTemp);
      this.closeMe();
    },
    resetSensorStats() {
      this.$emit("reset-sensor-stats", this.sensorTemp.eui);
    },
    insertNewSensor() {
      this.$emit("add-sensor", this.sensorTemp);
      this.closeMe();
    },
    closeMe() {
      this.$emit("close");
    },
    /* Calibration Modal */
    changeCalibration(newCalibration) {
      this.$set(this.sensorTemp, "calibration", newCalibration);
      this.calibrationEditing = false;
    },
    closeCalib() {
      this.calibrationEditing = false;
    },
    calibrationEdit() {
      this.calibrationEditing = true;
    }
  },
  mounted() {
    if (this.sensor) {
      this.sensorTemp = JSON.parse(JSON.stringify(this.sensor)); // Deep Copy
    } else {
      this.$set(this.sensorTemp, "userConfig", {});
      this.$set(this.sensorTemp, "eui", "C0EE4000010");
      this.$set(
        this.sensorTemp,
        "bridgeSpan",
        this.newPosition ? this.newPosition.bridgeSpan : null
      );
      this.$set(
        this.sensorTemp,
        "bridgeSide",
        this.newPosition ? this.newPosition.bridgeSide : null
      );
      this.$set(this.sensorTemp, "type", this.newType);
      this.$set(this.sensorTemp, "position", {});
    }
  },
  computed: {
    // Ritorna tutti i possibili valori data una chiave dell'oggetto userConfig
    getAvailableOptionsByField() {
      let options;
      let mesUnits;
      let stringValues;
      switch (this.sensorTemp.type) {
        // Vibrometer
        case "vibrometer":
          options = {};
          mesUnits = {};
          stringValues = {};
          break;
        // Deck
        case "crackmeter":
          options = {};
          mesUnits = { alert: "mm" };
          stringValues = {};
          break;
        case "analog-node":
          options = this.sensorTemp.rev === "2.0" ? { channelNumber: [8, 16] } : {}
          mesUnits = {};
          stringValues = {};
          break;
        case "tiltmeter":
          if (this.sensorTemp.rev === "2.0") {
            options = {
              sampleRate: [2, 5, 15, 30],
              calibration: [1, 0, 0],
              activationThreshold: "degrees"
            };
            mesUnits = {
              sampleRate: "min"
            };
            stringValues = {};
          } else if (this.sensorTemp.rev === "3.0") {
            options = {
              channelNumber: [8, 16],
              sampleRate: [1, 2, 5, 15, 30],
              activationThreshold: "degrees"
            };
            mesUnits = {
              sampleRate: "min"
            };
            stringValues = {};
          }
          break;
        case "deck":
          if (this.sensorTemp.rev === "1.0") {
            options = {};
            mesUnits = {
              alert: "mm",
              activationThreshold: "mm"
            };
            stringValues = {};
          } else {
            options = {
              resolution: [0.012, 0.024]
            };
            mesUnits = {
              alert: "mm",
              resolution: "mg",
              activationThreshold: "mm"
            };
            stringValues = {};
          }
          break;
        case "accelerometer":
          if (this.sensorTemp.rev === "4.0") {
            options = {
              channelNumber: [8, 16],
              fSample: [40, 80, 160, 320, 640],
              timeSwitch: [1, 2, 6, 12, 24],
              resolution: [0.03125, 0.0625, 0.125],
              typeAcqu: [0, 1, 2, 3],
              lengthSample:
                this.sensorTemp.userConfig.typeAcqu === 1
                  ? [128, 256, 512]
                  : [128, 256, 512, 1024, 2048, 4096, 8192, 16384],
              confXYZ: [0, 1, 2, 3, 4, 5, 6],
              typeSync: [0, 1, 2, 3, 4, 5, 6]
            };
            stringValues = {
              typeAcqu: [
                "Trigger std",
                "Trigger_pk_pk",
                "Sync",
                "Sync + trigger_std"
              ],
              confXYZ: ["Tutti", "Z-Y", "Z", "Z-X", "X", "Y-X", "Y"]
            };
            mesUnits = {
              alert: "mg",
              activationThreshold: "mg",
              fSample: "Hz",
              timeSwitch: "hr.",
              resolution: "mg",
              lengthSample: "nr."
            };
          } else if (this.sensorTemp.rev === "3.0") {
            options = {
              fSample: [40, 80, 160, 320, 640],
              timeSwitch: [1, 2, 6, 12, 24],
              resolution: [0.03125, 0.0625, 0.125],
              typeAcqu: [0, 2, 3],
              lengthSample:
                this.sensorTemp.userConfig.typeAcqu === 1
                  ? [128, 256, 512]
                  : [128, 256, 512, 1024, 2048, 4096, 8192, 16384],
              confXYZ: [0, 1, 2, 3, 4, 5, 6],
              typeSync: [0, 1, 2, 3, 4, 5, 6]
            };
            stringValues = {
              typeAcqu: ["Trigger std", "Sync", "Sync + trigger_std"],
              confXYZ: ["Tutti", "Z-Y", "Z", "Z-X", "X", "Y-X", "Y"]
            };
            mesUnits = {
              alert: "mg",
              activationThreshold: "mg",
              fSample: "Hz",
              timeSwitch: "hr.",
              resolution: "mg",
              lengthSample: "nr."
            };
          } else {
            options = {
              sampleRate: [1, 2, 6, 12, 24],
              resolution: [0.25, 0.5, 1]
            };
            mesUnits = {
              alert: "mg",
              activationThreshold: "mg",
              sampleRate: "hr.",
              resolution: "mg"
            };
            stringValues = {};
          }
          break;
        default:
          return {};
      }
      return {
        options: options,
        mesUnits: mesUnits,
        stringValues: stringValues
      };
    },
    validForm() {
      const sensor = this.sensorTemp;
      const userConfig = sensor.userConfig;
      const validationSensorEUI = this.validateSensorEUI(sensor.eui)
      const validationSensorName = validateSensorName(sensor.userConfig.name)
      const isVibrometer = sensor.type === "vibrometer"
      const isAnalogNodeV2 = sensor.type === "analog-node" && sensor.rev === '2.0'
      const validationThreshold= validateThreshold(sensor, userConfig.threshold)
      const validationActivationTh= validateActivationTh(sensor, userConfig.activationThreshold)
      const validationOffsetClassC = validateOffsetClassC(sensor, userConfig.offsetClassC)
      const validationSubMulticast = validateSubMulticast(sensor, userConfig.subMulticast)
      const result = (
        validationSensorEUI &&
        validationSensorName &&
        (isVibrometer || (validationThreshold && (validationActivationTh || isAnalogNodeV2) && validationOffsetClassC && validationSubMulticast))
      )
      return result

    }
  },
  watch: {
    sensor: {
      handler(newValue) {
        this.sensorTemp = JSON.parse(JSON.stringify(newValue)); // Deep Copy
      },
      deep: true
    },
    sensorTemp: {
      handler(newValue) {
        // Acc V3
        if (newValue.type === "accelerometer" && newValue.rev === "4.0") {
          // Se passo a Trigger PkPk metto lengthSample ad un valore compatibile
          if (
            newValue.userConfig &&
            newValue.userConfig.typeAcqu === 1 &&
            newValue.userConfig.lengthSample > 256
          ) {
            this.sensorTemp.userConfig.lengthSample = 256;
          }
        }
      },
      deep: true
    },
    "sensorTemp.type": function(newValue, oldValue) {
      if (oldValue || !this.sensor) {
        if (oldValue !== newValue) {
          this.sensorTemp = {};
          this.$set(this.sensorTemp, "userConfig", {});
          this.$set(this.sensorTemp, "eui", "C0EE4000010");
          this.$set(
            this.sensorTemp,
            "bridgeSpan",
            this.newPosition ? this.newPosition.bridgeSpan : null
          );
          this.$set(
            this.sensorTemp,
            "bridgeSide",
            this.newPosition ? this.newPosition.bridgeSide : null
          );
          this.$set(this.sensorTemp, "type", newValue);
          this.$set(this.sensorTemp, "position", {});
        }
        this.loadDefaultValues(newValue, null);
      }
      this.firstSensorValue = false;
    },
    "sensorTemp.rev": function(newValue, oldValue) {
      if (
        !this.sensor ||
        this.sensor.rev != newValue ||
        this.sensor.type != this.sensorTemp.type
      ) {
        console.log("Init default sensor");
        this.loadDefaultValues(null, newValue, oldValue);
      } else if (
        this.sensor.rev === newValue &&
        this.sensor.type === this.sensorTemp.type
      ) {
        this.sensorTemp = JSON.parse(JSON.stringify(this.sensor)); // Deep Copy
      }
      this.firstSensorValue = false;
    }
  }
};
</script>
<style scoped>
* {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
}
body {
  background-color: white !important;
}
/* h1 {
  font-size: 1em;
} */
h5 {
  margin: 0;
  font-weight: 700;
}
p {
  /* font-size: 0.8em; */
  color: rgb(198, 198, 198);
}
.horiz-divider {
  height: 1px;
  background-color: rgb(80, 84, 105);
  margin: 0 30px 0 30px;
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sensor-info {
  /* width: 40vw; */
  /* height: 40vh; */
  width: -webkit-fill-available;
  min-width: 840px;
  /* min-height: 560px; */
  max-width: 940px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  grid-template-columns: 50% 50%;
  /* grid-template-rows: 11% 44% 21% 11% 14%; */
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
#footer {
  background-color: rgb(45, 48, 65);
  border-top: solid 0.8px gray;
  height: fit-content;
  padding: 10px 0;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  -webkit-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
}

.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
.outer-wrap {
  top: 0;
  left: 0;
  z-index: 16;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.form-wrap {
  width: 100%;
  text-align: left;
  display: grid;
  padding: 10px 30px;
  box-sizing: border-box;
  grid-row: 2;
  grid-template-rows: 40px repeat(5, 38px);
  height: fit-content;
}
.side-form-wrap {
  width: 100%;
  text-align: left;
  display: grid;
  padding: 40px;
  box-sizing: border-box;
  grid-row: 2;
  grid-template-rows: 60px repeat(5, 32px);
}
.mails-container {
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgb(22, 25, 35);
  display: grid;
}

.mails-container > h5 {
  justify-self: center;
  align-self: center;
  font-style: italic;
}

.mail {
  height: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 50% 50%;
  padding: 10px;
  box-sizing: border-box;
}

.mail > p {
  margin: 0;
  /* font-size: 0.65em; */
  color: white;
  align-items: center;
}

div.mail:nth-child(even) {
  background: rgba(45, 48, 65, 0.6);
}

div.mail:nth-child(odd) {
  background: rgb(32, 34, 47);
}

.form-header {
  width: 100%;
  border-bottom: solid 1px rgb(80, 84, 105);
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 10px;
}

#sensor-header {
  background-color: rgb(45, 48, 65);
  height: fit-content;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  border-bottom: solid 2px white;
}
label {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.9em;
  font-weight: 700;
  grid-column: 1;
  text-align: left;
  align-self: center;
  white-space: pre;
  justify-self: baseline;
  color: rgb(150, 153, 162);
}
input {
  /* font-size: 0.7em; */
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}
input[type="checkbox"] {
  align-self: center;
}

.static-h {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
}

.buttons-container {
  display: grid;
  height: 30px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: 25% 20% 25% 5% 25%;
  margin-top: 35px;
}
.buttons-container > button {
  font-weight: 700;
  outline-width: 0;
  outline: none !important;
  color: white;
  border-radius: 10px;
  border: solid 2px white;
  background-color: rgb(80, 84, 105);
}
/* Statistiche sensore */
#stats {
  grid-column: 1/3;
  grid-row: 3;
  text-align: left;
  margin: 0 auto;
  grid-template-rows: auto;
}
.stats-container {
  display: flex;
  justify-content: space-around;
  grid-column: 1/3;
  align-items: center;
}
.stats-container > p {
  color: white;
  margin: 0;
}
.strong-text {
  font-weight: 800;
}
#reset-stats {
  margin: 0;
  padding: 5px;
}
/* Control Buttons */
.footer-btn {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  padding: 0 40px 0 40px;
}
.button {
  width: fit-content;
  /* font-size: 0.8em; */
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  white-space: nowrap;
  transition: transform 0.1s linear;
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.embed-btn {
  width: auto;
  box-sizing: border-box;
  border: solid 1.5px grey;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 0px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  line-height: 1em;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47);
}
/* .buttons-container > #reset{
	grid-column: 1;
}
.buttons-container > #edit{
	grid-column: 3;
}*/
.buttons-container > #save {
  background-color: rgb(21, 146, 230);
}
.select-options {
  border-width: 0px;
  /* font-size: 0.7em; */
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47);
}
/* .select-options:disabled+label{
  color: rgba(128, 128, 128, 0.39)
} */
.select-options:disabled {
  background-color: rgba(128, 128, 128, 0.39);
}

input[type="text"]:disabled {
  background-color: rgba(128, 128, 128, 0.39) !important;
}

.valid-field {
  border: solid 1.2px green;
}
.invalid-field {
  border: solid 1.2px red;
}
.static-field {
  background-color: initial !important;
}
input:disabled {
  border-color: transparent;
}
button:disabled {
  border-color: transparent;
}

::-webkit-scrollbar {
  width: 0.3em;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(80, 84, 105);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
</style>
