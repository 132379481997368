<template>
  <div class="outer-wrap">
    <div class="groups-wrapper">
      <move-confirm-modal message="Sei sicuro di voler cancellare questo gruppo?" @confirm="onConfirmDeleteGroup" @decline="onDeclineDeleteGroup" class="confirm-modal" v-if="showConfirmModal" />
      <header>
        <move-text bold>FDD</move-text>
        <a @click="close" class="close"></a>
        <a @click="close" class="close"></a>
      </header>
      <div id="groups-container">
        <h3>Groups</h3>
        <div class="btns"></div>
        <div class="list-container">
          <div
            @click="onClickSelectGroup(group)"
            v-for="group in groupsToDisplay"
            :key="group._id"
          >
            <div :class="['list-item']">
              <div class="header">
                <div class="group-name">
                  <move-btn
                    class="btn-expand"
                    noWrap
                    icon
                    block
                    @click="onClickExpandGroup(group)"
                  >
                    <font-awesome-icon
                      :icon="
                        group.expanded
                          ? 'fa-solid fa-angle-up'
                          : 'fa-solid fa-angle-down'
                      "
                  /></move-btn>
                  <move-text class="title text-ellipsis">{{
                    group.name
                  }}</move-text>
                  <move-text
                    v-if="group.notes"
                    class="notes text-ellipsis"
                    paragraph
                  >
                    > {{ group.notes }}</move-text
                  >
                </div>
                <div class="group-actions">
                  <move-btn noWrap icon block @click="onClickEditGroup(group)">
                    <font-awesome-icon icon="fa-solid fa-pen"
                  /></move-btn>
                  <move-btn
                    noWrap
                    icon
                    block
                    @click="onClickDeleteGroup(group)"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash"
                  /></move-btn>
                </div>
              </div>
              <div v-if="group.expanded" class="expand-content">
                <move-text class="title">Sensori</move-text>
                <div class="list">
                  <div
                    class="item"
                    v-for="device in group.devices"
                    :key="device.eui"
                  >
                    <move-text paragraph>
                      {{ shortDeviceName(device.eui) }}
                    </move-text>
                    <move-switch
                      :disabled="true"
                      v-model="device.enabled"
                    ></move-switch>
                    <move-text class="subtitle" paragraph>{{
                      device.enabled ? "enabled" : "disabled"
                    }}</move-text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- pagination -->
        <div class="pagination">
          <move-text paragraph class="rows-per-page">
            Rows per page: {{ maxGroupsPerPage }}
          </move-text>
          <move-text class="page-indicator" paragraph
            >{{ getPaginationCurrentPage + 1 }} of
            {{ getPaginationMaxPage + 1 }}</move-text
          >
          <move-btn
            icon
            :disabled="getPaginationCurrentPage <= 0"
            @click="onClickPrevPage"
            ><font-awesome-icon icon="fa-solid fa-angle-left"
          /></move-btn>
          <move-btn
            icon
            :disabled="getPaginationCurrentPage >= getPaginationMaxPage - 1"
            @click="onClickNextPage"
            ><font-awesome-icon icon="fa-solid fa-angle-right"
          /></move-btn>
        </div>
      </div>
      <footer class="buttons-container">
        <move-btn large rounded primary block outlined @click="onClickAddGroup"
          ><move-text h2 bold>ADD GROUP</move-text></move-btn
        >
      </footer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MoveConfirmModal from "../../ui_V2/MoveConfirmModal.vue"
const MAX_GROUP_PER_PAGE = 50;

export default {
  name: "ListGroups",
  components: {
    MoveConfirmModal,
  },
  data() {
    return {
      maxGroupsPerPage: MAX_GROUP_PER_PAGE,
      groupsToDisplay: [],
      additionalFields: {
        selected: false,
        expanded: false
      },
      showConfirmModal: false,
      tempGroup: null
    };
  },
  computed: {
    ...mapGetters({
      getGroupsInfo: "group/getGroupsInfo",
      getSync: "group/getSync",
      getSelectedStructure: "admin/getSelectedStructure",
      getSelectedUser: "admin/getSelectedUser"
    }),
    getUserID() {
      return this.getSelectedUser && this.getSelectedUser._id
        ? this.getSelectedUser._id
        : null;
    },
    getStructureID() {
      return this.getSelectedStructure && this.getSelectedStructure._id
        ? this.getSelectedStructure._id
        : null;
    },
    getValidateUserAndStructureID() {
      return Boolean(this.getUserID && this.getStructureID);
    },
    getPaginationCurrentPage() {
      return this.getGroupsInfo.page;
    },
    getGroupPerPage() {
      return this.getGroupsInfo.perPage;
    },
    getGroupTotal() {
      return this.getGroupsInfo.total;
    },
    getGroups() {
      return this.getGroupsInfo.values;
    },
    groupSelected() {
      return this.groupsToDisplay.find(g => g.selected === true);
    },
    getPaginationMaxPage() {
      return (
        Math.floor(this.getGroupTotal / this.getGroupPerPage) +
        (this.getGroupTotal % this.getGroupPerPage > 0 ? 1 : 0)
      );
    },
    getPaginationItemsPerPage() {
      return this.getGroupsInfo.perPage;
    }
  },

  methods: {
    ...mapActions({
      fetchGroups: "group/fetchGroups",
      createGroup: "group/createGroup",
      editGroup: "group/editGroup",
      deleteGroup: "group/deleteGroup"
    }),
    async onConfirmDeleteGroup(){
      if (!this.tempGroup) {
        this.showConfirmModal = false;
        return;
      }
      await this.deleteGroup({
        structureID: this.getStructureID,
        userID: this.getUserID,
        group: { _id: this.tempGroup._id }
      })
      this.tempGroup = null;
      this.showConfirmModal = false;
    },
    onDeclineDeleteGroup(){
      this.showConfirmModal = false;
    },
    shortDeviceName(eui) {
      return eui.substring(eui.length - 5, eui.length);
    },
    async onClickPrevPage() {
      await this.requestGroups(this.getPaginationCurrentPage - 1);
    },
    async onClickNextPage() {
      await this.requestGroups(this.getPaginationCurrentPage + 1);
    },
    //Select/deselect group when click on group.
    onClickSelectGroup(group) {
      this.groupsToDisplay = this.groupsToDisplay.map(g => {
        return g._id === group._id
          ? { ...g, ...{ selected: !group.selected } }
          : { ...g, ...{ selected: false } };
      });
    },
    onClickExpandGroup(group) {
      this.groupsToDisplay = this.groupsToDisplay.map(g => {
        return g._id === group._id
          ? { ...g, ...{ expanded: !group.expanded } }
          : g;
      });
    },
    //Edit name group when click on edit button
    onClickEditGroup(group) {
      this.$emit("edit-group", group);
    },
    //Edit name group when click on edit button
    async onClickDeleteGroup(group) {
      this.showConfirmModal = true;
      this.tempGroup = group;
    },
    //Emit an event when click on add group button
    onClickAddGroup() {
      this.$emit("add-group");
    },
    close() {
      this.$emit("close-groups");
    },

    async requestGroups(page = 0) {
      await this.fetchGroups({
        structureID: this.getStructureID,
        userID: this.getUserID,
        params: {
          limit: this.maxGroupsPerPage,
          page: page
        }
      });
    }
  },

  watch: {
    getSync: {
      handler: async function(synchronized) {
        if (!synchronized) await this.requestGroups();
      }
    },
    getValidateUserAndStructureID: {
      handler: async function(valid) {
        if (valid) await this.requestGroups();
      }
    },
    getGroups: {
      handler: function(groups) {
        this.groupsToDisplay = [];
        this.groupsToDisplay = groups.map((g, i) => {
          const value = { ...g, ...this.additionalFields };
          value.selected = i === 0 ? true : false;
          return value;
        });
      },
      deep: true
    }
  },

  async created() {
    await this.requestGroups();
  }
};
</script>

<style scoped>
.confirm-modal{
  position: absolute;
  top: -60px;
}

.rows-per-page {
  margin-right: 32px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
h3 {
  margin: 5px 0 10px 0;
}
.outer-wrap {
  z-index: 16;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.groups-wrapper {
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  overflow: hidden;
  width: 50vw;
  min-width: 800px;
  height: fit-content;
  background-color: rgb(45, 48, 65);
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 60px 10fr 80px;
  height: 90vh;
  max-height: 600px;
  min-height: 400px;
}
header {
  position: relative;
  padding: 0 16px;
  display: flex;
  align-items: center;
  grid-column: 1/3;
  border-bottom: solid 2px white;
  color: white;
}
footer {
  position: relative;
  grid-column: 1/3;
}
#groups-container {
  grid-row: 2;
  background-color: rgb(45, 48, 65);
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.list-container {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgb(22, 25, 35);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}
.list-item {
  text-align: left;
  padding: 4px 4px;
  background-color: rgb(45, 48, 65);
  margin: 6px;
  border-radius: 4px;
}

.more-btn {
  margin: 6px;
}

.list-item > .header {
  display: flex;
  justify-content: space-between;
}

.list-item > .expand-content {
  margin: 8px 8px 4px 8px;
  border-top: 2px solid var(--secondary-color-light-3);
}

.expand-content > .title {
  margin: 8px 0px 4px 0px;
}

.expand-content > .list {
  background-color: var(--secondary-color-dark);
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
}

.expand-content > .list > .item {
  display: grid;
  gap: 4px;
  grid-template-columns: 50px 30px 60px;
  align-items: center;
}

.list-item.active {
  background-color: rgb(21, 146, 230);
}

.list-item > label {
  margin-left: 4px;
}

.selected-item {
  background-color: rgb(21, 146, 230);
}
.btns {
  display: flex;
  justify-content: center;
}
.group-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4px 0;
  outline: none;
  border: none;
  background-color: rgb(80, 84, 105);
  color: white;
  font-weight: 800;
  font-size: 1.4em;
  border-radius: 5px;
}

.group-btn.btn-large {
  padding: 8px;
  margin: 0px;
}

.group-btn-circle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 25px;
  width: 25px;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: rgb(80, 84, 105);
  color: white;
  font-weight: 800;
  font-size: 1.4em;
  display: inline;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
}

input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin: auto 15px auto;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}

input[type="text"] {
  /* font-size: 0.7em; */
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
  width: 100%;
}

input[type="text"]:disabled {
  background-color: rgba(128, 128, 128, 0.39) !important;
}

.valid-field {
  border: solid 1.2px green;
}
.invalid-field {
  border: solid 1.2px red;
}
input:disabled {
  border-color: transparent;
}

.group-actions {
  display: flex;
}

.group-name {
  display: flex;
  align-items: center;
  justify-content: left;
  overflow-x: hidden;
  width: 100%;
}

.group-name > .notes {
  margin-left: 8px;
  max-width: 400px;
}

.group-name > .title {
  max-width: 400px;
}

.group-name > .btn-expand {
  padding: 0px 12px;
}

.group-name .text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
input[type="checkbox"].mx0 {
  margin: 0px;
  margin-right: 8px;
}
</style>
