<template>
  <div class="outer-wrap">
    <div class="groups-wrapper">
      <header>
        <move-text bold>{{ this.mode.editing ? "Edit" : "Add" }}</move-text>
        <a @click="close" class="close"></a>
      </header>
      <div id="main-container">
        <div class="form">
          <div class="form-item">
            <label>Nome</label>
            <move-input
              large
              v-model.trim="groupName"
              @input="onChangeGroupName"
              :valid="isNameValid"
              :maxlength="groupNameMaxLength  "
              ><font-awesome-icon
                color="red"
                v-if="isValidatingOnBackend"
                icon="fa-solid fa-spinner"
            /></move-input>
          </div>
          <div class="form-item">
            <label>Note</label>
            <move-input large v-model.trim="groupNote" :maxlength="groupNotesMaxLength"></move-input>
          </div>
          <div class="form-item">
            <label>Tipo</label>
            <move-select
              :value="groupType"
              :options="groupTypeOptions"
            ></move-select>
          </div>
        </div>
        <div class="list-of-sensors-header">
          <div class="title">
            <move-text>Sensori</move-text>
            <move-text class="error-color" paragraph
              >{{
                !validateType ? "You have to select devices with same type" : ""
              }}
            </move-text>
          </div>

          <div class="filter-options">
            <move-input large v-model.trim="searchDeviceText"
              ><font-awesome-icon icon="fa-solid fa-magnifying-glass"
            /></move-input>
          </div>
        </div>
        <div class="list-container">
          <div
            class="list-item"
            v-for="device in groupDeviceToDisplay"
            :key="device.eui"
          >
            <input
              type="checkbox"
              :name="device.eui"
              :id="device.eui"
              v-model="device.selected"
              
            />
            <div class="device-container">
              <div class="device-info">
                <move-text
                  >{{ device.type }} - v{{
                    device.rev ? device.rev : "???"
                  }}</move-text
                >
                <move-text>{{ shortDeviceName(device.eui) }}</move-text>
                <move-btn
                  class="btn-expand"
                  noWrap
                  icon
                  block
                  @click="device.expanded = !device.expanded"
                >
                  <font-awesome-icon
                    :icon="
                      device.expanded
                        ? 'fa-solid fa-angle-up'
                        : 'fa-solid fa-angle-down'
                    "
                /></move-btn>
              </div>
              <div class="activator">
                <move-switch
                  :disabled="true"
                  v-model="device.enabled"
                ></move-switch>
                <move-text class="subtitle" paragraph>{{
                  device.enabled ? "enabled" : "disabled"
                }}</move-text>
              </div>
            </div>
            <div v-if="device.expanded" class="devices-notes">
              <move-text class="flex-item input-label" h2>Notes</move-text>
              <move-input
                v-model.trim="device.notes"
                class="flex-item input-text"
                small
                :maxlength ="deviceNotesMaxLength"
              ></move-input>
            </div>
          </div>
        </div>
      </div>
      <footer class="buttons-container">
        <move-btn large rounded secondary block outlined @click="onClickBack"
          ><move-text h2 bold>BACK</move-text></move-btn
        >
        <move-btn
          large
          rounded
          primary
          block
          outlined
          :disabled="!validation"
          @click="onClickSave"
          ><move-text h2 bold>SAVE</move-text></move-btn
        >
      </footer>
    </div>
  </div>
</template>
<script>
import { clone, deletePropertiesFromObject } from "../../../helpers/utils";
import { mapGetters, mapActions } from "vuex";
import { validateGroupName } from "../../../services/api";
export default {
  name: "ListGroups",
  props: {
    structure: Object,
    sensorType: String,
    mode: {
      type: Object,
      default: () => {
        return {
          editing: false,
          group: {}
        };
      }
    }
  },
  data() {
    return {
      sameType: true,
      searchDeviceText: "",
      groupID: null,
      groupName: "",
      groupNote: "",
      groupType: "fdd",
      groupTypeOptions: ["fdd"],
      groupDevices: [], //original device with "devicesAdditionalFields", used in this component
      hint: "",
      devicesAdditionalFields: {
        visible: true,
        expanded: false,
        selected: false,
        notes: "",
        enabled: true
      },
      isValidatingOnBackend: false,
      isNameValid: false,
      timerBackendValidation: null,
      delayBackendValidation: 1000,

      groupNameMaxLength: 64,
      groupNotesMaxLength: 256,
      deviceNotesMaxLength: 256,
    };
  },
  //********************************************************************************
  //COMPUTED
  computed: {
    ...mapGetters({
      getSensors: "structure/getSensors",
      getGroupsInfo: "group/getGroupsInfo",
      getSelectedStructure: "admin/getSelectedStructure",
      getSelectedUser: "admin/getSelectedUser"
    }),
    getEnableGroupNameBackendValidation() {
      return this.getGroupsInfo.total > this.getGroupsInfo.perPage;
    },
    getUserID() {
      return this.getSelectedUser && this.getSelectedUser._id
        ? this.getSelectedUser._id
        : null;
    },
    getStructureID() {
      return this.getSelectedStructure && this.getSelectedStructure._id
        ? this.getSelectedStructure._id
        : null;
    },
    disableSaveBtn() {
      const devicesSelected = this.groupDevices.filter(
        s => s.selected === true
      );
      return this.groupName === "" || devicesSelected.length === 0;
    },
    devicesSelected() {
      //create a deep copy of groupDevices
      let devicesSelected = clone(this.groupDevices);
      //Filter groupDevices not selected and remove selected property
      return devicesSelected
        .filter(s => s.selected === true)
        .map(s =>
          deletePropertiesFromObject(s, [
            "selected",
            "expanded",
            "type",
            "visible",
            "rev"
          ])
        );
    },

    groupDeviceToDisplay() {
      return this.groupDevices.filter(g => g.visible === true);
    },
    group() {
      const group = {
        name: this.groupName,
        notes: this.groupNote,
        type: this.groupType,
        devices: this.devicesSelected
      };
      //add _id if available
      if (this.mode.editing && this.groupID) group._id = this.groupID;
      return group;
    },
    listOfGroups() {
      return this.getGroupsInfo.values;
    },
    listOfGroupsName() {
      return this.getGroupsInfo.values.map(g => g.name);
    },
    validateType() {
      return (
        this.sameType &&
        this.groupDevices
          .filter(g => g.selected === true)
          .map(g => g.type)
          .every((g, _i, arr) => g === arr[0])
      );
    },
    validation() {
      return this.isNameValid && this.validateType;
    }
  },
  //********************************************************************************
  //METHODS
  methods: {
    ...mapActions({
      createGroup: "group/createGroup",
      editGroup: "group/editGroup"
    }),
    async backendValidation(name) {
      return new Promise((resolve) => {
        this.isValidatingOnBackend = true;
        if (this.timerBackendValidation) {
          clearTimeout(this.timerBackendValidation);
        }
        this.timerBackendValidation = setTimeout(async () => {
          const response = await validateGroupName({
            url: {
              customerID: this.getUserID,
              structureID: this.getStructureID,
              name: name
            },
            params: {
              type: this.groupType
            }
          });
          this.isValidatingOnBackend = false;
          resolve(Boolean(response.available));
        }, this.delayBackendValidation);
      });
    },
    async validateName(name) {
      if (this.mode.editing && this.mode.group.name === name) {
        this.isNameValid = true;
      } else if (this.getEnableGroupNameBackendValidation) {
        this.isNameValid = false;
        this.isNameValid = await this.backendValidation(name);
      } else {
        this.isNameValid = name !== "" && !this.listOfGroupsName.includes(name);
      }
    },
    async onChangeGroupName(name) {
      await this.validateName(name);
    },
    shortDeviceName(eui) {
      return eui.substring(eui.length - 5, eui.length);
    },
    //Emit an event when click on back button
    onClickBack() {
      this.$emit("back");
    },
    //Emit an event when click on save button with group
    async onClickSave() {
      if (this.mode.editing) {
        await this.editGroup({
          structureID: this.getStructureID,
          userID: this.getUserID,
          group: this.group
        });
      } else {
        await this.createGroup({
          structureID: this.getStructureID,
          userID: this.getUserID,
          group: this.group
        });
      }
      this.$emit("save", { group: this.group, editing: this.mode.editing });
    },
    //Emit an event when close the modal
    close() {
      this.$emit("close-groups");
    },
    //create groupDevices data structure
    init() {
      //create a deep copy of original getSensors
      let groupDevices = clone(this.structure.sensors);
      //1 - Filter sensors by type.
      //2 - Get a subset of original sensors strucutre and add useful fields for this component
      this.groupDevices = groupDevices
        .filter(d => d.type === "deck" || d.type === "accelerometer")
        .map(d => {
          return {
            ...{ eui: d.eui, type: d.type, rev: d.rev },
            ...this.devicesAdditionalFields
          };
        });
    },
    loadGroup(
      group = {
        _id: undefined,
        name: "",
        notes: "",
        type: "",
        devices: []
      }
    ) {
      this.groupID = group._id;
      this.groupType = group.type;
      this.groupName = group.name;
      this.groupNote = group.notes;
      //load devices
      this.groupDevices = this.groupDevices.map(d => {
        const selectedDevices = group.devices.find(sd => sd.eui === d.eui);
        return selectedDevices
          ? { ...d, ...selectedDevices, ...{ selected: true } }
          : d;
      });
    }
  },
  watch: {
    searchDeviceText: {
      handler: function(text) {
        this.groupDevices = this.groupDevices.map(g => {
          g.visible = true;
          if (text && !g.eui.includes(text) && !g.type.includes(text))
            g.visible = false;
          return g;
        });
      }
    }
  },
  async created() {
    this.init();
    //if it's in editing mode
    if (this.mode.editing) {
      this.loadGroup(this.mode.group);
      this.isNameValid = true;
    }
  }
};
</script>
<style scoped>
.validation-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
}

.filter-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.same-type {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.same-type > .item-checkbox {
  margin: 2px;
}

.same-type > .item-label {
  margin: 2px;
}
.list-of-sensors-header {
  border-top: 2px solid var(--secondary-color-light-3);
  margin-top: 8px;
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.list-of-sensors-header > h3 {
  margin: 0px;
}

.list-of-sensors-header > .title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}
.device-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.device-container > .subtitle {
  margin-left: 12px;
}

.device-container > .device-info {
  display: grid;
  grid-template-columns: 300px 80px 120px;
}
.devices-notes {
  border-top: 2px solid var(--secondary-color-light-3);
  padding-top: 8px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-column: 1 / span 2;
}

.devices-notes > .input-text {
  width: 100%;
  margin-bottom: 4px;
}

h3 {
  margin: 4px 0px;
}
p {
  margin: 4px 0px 8px 0px;
}
.outer-wrap {
  z-index: 16;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.groups-wrapper {
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  overflow: hidden;
  width: 50vw;
  min-width: 800px;
  height: fit-content;
  background-color: rgb(45, 48, 65);
  display: grid;
  grid-template-rows: 60px 10fr 80px;
  height: 90vh;
  max-height: 600px;
  min-height: 400px;
}
header {
  position: relative;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-bottom: solid 2px white;
  color: white;
}
footer {
  position: relative;
}
#main-container {
  background-color: rgb(45, 48, 65);
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.list-container {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgb(22, 25, 35);
  border-radius: 4px;
  width: 100%;
  height: 100%;
}
.list-item {
  text-align: left;
  padding: 6px 12px;

  background-color: rgb(45, 48, 65);
  margin: 6px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto auto;
  align-items: center;
}

.buttons-container {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
}

input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin: 0px;
}
.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}

input[type="text"] {
  /* font-size: 0.7em; */
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}

input[type="text"].valid {
  border: solid 1.2px green;
}

input[type="text"].not-valid {
  border: solid 1.2px red;
}

input:disabled {
  border-color: transparent;
}

input[type="checkbox"].mx0 {
  margin: 0px;
  margin-right: 8px;
}
.form-item {
  margin: 4px 0px;
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: center;
}

.form-item > label {
  text-align: left;
}

.form {
  margin-top: 16px;
}

.activator {
  width: 100px;
  margin-left: 12px;
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  justify-content: left;
}

.error-color {
  padding-left: 8px;
  color: red;
}
</style>
