<template>
  <div class="wrapper noselect">
    <div class="toggle_radio">
      <input type="radio" class="toggle_option" id="first_toggle" value="deck" :disabled="!this.searchForType(this.sensors, 'deck') || !this.availableTypes.includes('deck')" v-model="selected" />
      <input
        type="radio"
        class="toggle_option"
        id="second_toggle"
        value="tiltmeter"
        :disabled="!this.searchForType(this.sensors, 'tiltmeter') || !this.availableTypes.includes('tiltmeter')"
        v-model="selected"
      />
      <input
        type="radio"
        class="toggle_option"
        id="third_toggle"
        value="accelerometer"
        :disabled="!this.searchForType(this.sensors, 'accelerometer') || !this.availableTypes.includes('accelerometer')"
        v-model="selected"
      />
      <input
        type="radio"
        class="toggle_option"
        id="fourth_toggle"
        value="crackmeter"
        :disabled="!this.searchForType(this.sensors, 'crackmeter') || !this.availableTypes.includes('crackmeter')"
        v-model="selected"
      />
      <input
        type="radio"
        class="toggle_option"
        id="fifth_toggle"
        value="analog-node"
        :disabled="!this.searchForType(this.sensors, 'analog-node') || !this.availableTypes.includes('analog-node')"
        v-model="selected"
      />
      <input type="radio" class="toggle_option" id="sixth_toggle" value="vibrometer" :disabled="!this.searchForType(this.sensors, 'vibrometer') || !this.availableTypes.includes('vibrometer')" v-model="selected" />
      <label for="first_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'deck') || !this.availableTypes.includes('deck') }">{{ $t("message.deck") }}</p>
      </label>
      <label for="second_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'tiltmeter') || !this.availableTypes.includes('tiltmeter') }">{{ $t("message.tiltmeter") }}</p>
      </label>
      <label for="third_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'accelerometer') || !this.availableTypes.includes('accelerometer') }">{{ $t("message.accelerometer") }}</p>
      </label>
      <label for="fourth_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'crackmeter') || !this.availableTypes.includes('crackmeter') }">{{ $t("message.crackmeter") }}</p>
      </label>
      <label for="fifth_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'analog-node') || !this.availableTypes.includes('analog-node') }">{{ $t("message.analogNode") }}</p>
      </label>
      <label for="sixth_toggle">
        <p :class="{ greyed: !this.searchForType(this.sensors, 'vibrometer') || !this.availableTypes.includes('vibrometer') }">{{ $t("message.vibrometer") }}</p>
      </label>
      <div class="toggle_option_slider"></div>
    </div>
  </div>
</template>
<script>
/* 
  Sensor Toggle utilizzato da BridgeDraw, BridgeDrawMin e vecchio generic
*/
export default {
  name: "sensor-toggle",
  props: {
    sensors: Array,
    showAll: Boolean,
    selectedSensor: Object,
    typesFilter: Array,
  },
  data() {
    return {
      selected: "",
      availableTypes: ["deck", "tiltmeter", "accelerometer", "crackmeter", "analog-node", "vibrometer"],
    };
  },
  methods: {
    searchForType: function(sensorsList, type) {
      let check = false;
      sensorsList.forEach((element) => {
        if (element.type === type) check = true;
      });
      return check || this.showAll;
    },
    getFirstSensorSelection: function() {
      for (let type of this.availableTypes) {
        if (this.searchForType(this.sensors, type)) {
          return type;
        }
      }
    },
    selectType: function(newType) {
      this.$emit("type-selected", newType);
    },
  },
  watch: {
    selected(newType) {
      this.selectType(newType);
    },
    /* Se cambio il sensore selezionato da fuori adeguo il tipo */
    selectedSensor(sensor) {
      if (sensor && sensor.type != this.selected) {
        this.selected = sensor.type;
      } else if (!sensor) {
        this.selected = null;
      }
    },
    sensors(newSensors, oldSensors) {
      if (newSensors[0] && oldSensors[0] && newSensors[0]._id != oldSensors[0]._id) {
        this.selected = this.getFirstSensorSelection();
      }
    },
    typesFilter(newFilter) {
      if (newFilter) {
        this.availableTypes = newFilter;
        this.getFirstSensorSelection();
      } else {
        this.availableTypes = [];
      }
    },
  },
  mounted() {
    if (this.typesFilter) {
      this.availableTypes = this.typesFilter;
    }
    this.selected = this.getFirstSensorSelection();
  },
};
</script>
<style scoped>
p {
  margin: 0px 5px;
  padding: 0px;
  position: relative;
  z-index: 2;
}

.toggle_radio {
  position: relative;
  background-color: rgb(32, 34, 47);
  padding: 0 !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 47px;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: repeat(6, 16.67%);
  /* width: 700px; */
  box-sizing: border-box;
  justify-items: center;
}
.toggle_radio > * {
  float: left;
}
.toggle_radio input[type="radio"] {
  display: none;
}
.toggle_radio label {
  color: rgba(255, 255, 255, 0.9);
  font-family: inherit;
  font-weight: 600;
  font-size: 0.9em;
  display: block;
  width: max-content;
  box-sizing: border-box;
  height: 20px;
  margin: 9px 22px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.toggle_option_slider {
  width: 16%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 38px;
  align-self: center;
  justify-self: baseline;
  position: absolute;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#first_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 0%; */
  transform: translateX(1%);
}
#second_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 25%; */
  transform: translateX(102%);
}
#third_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  /* left: 50%; */
  transform: translateX(207%);
}
#fourth_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(310%);
  /* left: 75%; */
}
#fifth_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(414%);
  /* left: 75%; */
}
#sixth_toggle:checked ~ .toggle_option_slider {
  background-color: rgba(21, 146, 230, 1);
  transform: translateX(518%);
  /* left: 75%; */
}
.greyed {
  color: rgb(80, 84, 105);
}

@media all and (max-width: 1799px) and (min-width: 300px) {
  .toggle_radio {
    height: 37px;
  }
  .toggle_option_slider {
    height: 28px;
  }
}
</style>
