function clone(obj = {}){
    return JSON.parse(JSON.stringify(obj))
} 

function deletePropertiesFromObject(obj = {}, properties = []){
    const result = clone(obj)
    properties.forEach(property => {
      if (Object.prototype.hasOwnProperty.call(result, property)) delete result[property];
    });
    return result;
}

module.exports = {
    clone,
    deletePropertiesFromObject
};