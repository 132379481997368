<template>
  <ModalTemplate :loading="apiLoading">
    <div id="root" slot="body">
      <!-- Form -->
      <div class="sensor-form">
        <h2>Filtro sensori in inventario:</h2>
        <div class="form-field">
          <label for="eui">Eui:</label>
          <input type="text" placeholder="Filtra per eui..." name="eui" ref="eui" min="0" step="any" class="input-text" spellcheck="false" maxlength="23e" v-model="search" />
        </div>
        <div class="form-field">
          <label for="type">Tipo:</label>
          <select id="type" v-model="type">
            <option v-for="sType in sensorTypes" :key="sType" :value="sType">{{ sType }}</option>
          </select>
        </div>
        <div class="form-field">
          <label for="type">Rev:</label>
          <select id="type" v-model="rev">
            <option v-for="revision in sensorRevs[type]" :key="revision" :value="revision">{{ revision + (isLastRev(revision, type) ? " (latest)" : " (legacy)") }}</option>
          </select>
        </div>
        <div class="form-field">
          <label for="type">Region:</label>
          <select id="type" v-model="region">
            <option v-for="reg in sensorRegions[type]" :key="reg" :value="reg">{{ reg }}</option>
          </select>
        </div>
        <div class="form-field">
          <button class="std-button noselect" @click="setSelectedAvailableSensors([])">Reset</button>
        </div>
        <!-- <p>{{ getAvailableDevices ? getAvailableDevices.sensors.length : " - " }} sensori disponibili.</p> -->
      </div>

      <!-- SENSORE -->
      <!-- Dettagli singolo sensore -->
      <div class="sensor-details" v-if="type !== 'gateway' && getAvailableSensorDetails.sensor && getSelectedAvailableSensors.length === 1">
        <h2>Dettagli sensore selezionato:</h2>
        <!-- <p>EUI: {{ this.getAvailableSensorDetails.sensor }}</p> -->
        <p>
          <span class="strong"> EUI: {{ getAvailableSensorDetails.sensor.deveui }}</span>
        </p>
        <p><span class="strong"> FW: </span>{{ getAvailableSensorDetails.sensor.config.fwver || " - " }}</p>
        <p><span class="strong"> Lot. num.: </span>{{ getAvailableSensorDetails.sensor.lot_num || " - " }}</p>
        <p><span class="strong"> Pcb Ver.: </span>{{ getAvailableSensorDetails.sensor.pcb_ver || " - " }}</p>
        <p><span class="strong"> Distributore: </span>{{ getAvailableSensorDetails.sensor.resellerID || " - " }}</p>
        <p><span class="strong"> App. Key: </span>{{ getAvailableSensorDetails.sensor.config.appkey || " - " }}</p>
        <p><span class="strong"> App. EUI: </span>{{ getAvailableSensorDetails.sensor.config.appeui || " - " }}</p>
        <p><span class="strong"> Data collaudo: </span>{{ new Date(getAvailableSensorDetails.sensor.date).toLocaleString() }}</p>
      </div>

      <div class="sensor-details empty-container" v-else-if="getSelectedAvailableSensors.length === 0">
        <h2>Nessun sensore selezionato...</h2>
      </div>
      <!-- Insieme sensori selezionati -->
      <div class="sensor-details empty-container bright-container" v-if="getSelectedAvailableSensors.length > 1">
        <h2>Selezionati {{ getSelectedAvailableSensors.length }} sensori di tipo {{ type }}</h2>
      </div>
      <!-- Lista -->
      <div class="sensor-list">
        <h2>Lista sensori disponibili ({{ (getAvailableDevices && getAvailableDevices.sensors.length) || " - " }}):</h2>
        <div class="list-wrapper scroll">
          <div
            :class="[isSensorSelected(sensor._id) ? 'selected-item' : '', 'sensor-item noselect-hard']"
            v-for="(sensor, index) in (getAvailableDevices || {}).sensors"
            :key="sensor._id"
            @click="handleSensorItemClick($event, sensor._id)"
          >
            <p>{{ index + 1 }}</p>
            <p>{{ sensor.deveui + " (" + sensor.config.type + ")" }}</p>
          </div>
        </div>
      </div>
      <div class="bottom-controls">
        <button class="std-button noselect" @click="$emit('close')">Annulla</button>
        <button class="std-button green-bg noselect" :disabled="getSelectedAvailableSensors.length === 0" @click="assignSensor(selectedSensorID)">Assegna</button>
      </div>
    </div>
  </ModalTemplate>
</template>

<script>
/*
  Riceve come props il tipo di sensore da cercare di default.
  Si occupa di visualizzare l'inventario sensori secondo i filtri, e di dare
  la possibilità all'utente di assegnare il sensore selezionato alla struttura.
*/
import ModalTemplate from "./ModalTemplate.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "device-search",
  components: {
    ModalTemplate,
  },
  props: {
    initalType: {
      type: String,
      default: "deck",
    },
    sensorTypes: {
      type: Array,
      default: () => ["deck", "accelerometer", "analog-node", "tiltmeter", "vibrometer"],
    },
    singleSelect: Boolean,
    bridgeSpan: {
      type: Number,
      default: null,
    },
    bridgeSide: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      search: "", // Search query string
      type: "", // Search type
      rev: "", // Search rev
      region: "", // Search region
      selectedSensorID: "",
      sensorRevs: {
        deck: ["2.0"],
        tiltmeter: ["2.0", "3.0"],
        accelerometer: ["2.0", "3.0", "4.0"],
        "analog-node": ["1.0", "2.0"],
        vibrometer: ["1.0", "2.0"],
        gateway: ["1.0"],
      },
      sensorRegions: {
        deck: ["EU", "AU", "US"],
        tiltmeter: ["EU", "AU", "US"],
        accelerometer: ["EU", "AU", "US"],
        "analog-node": ["EU", "AU", "US"],
        vibrometer: ["EU", "AU", "US"],
        gateway: ["EU", "AU", "US"],
      },
      awaitingSearch: false,
      apiLoading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchAvailableDevices: "admin/fetchAvailableDevices",
      fetchAvailableSensorDetails: "admin/fetchAvailableSensorDetails",
      assignSensorsToStructure: "admin/assignSensorsToStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSelectedAvailableSensors: "admin/setSelectedAvailableSensors",
    }),
    /* Ritorna true se la revisione passata è l'ultima disponibile per quel tipo di sensore */
    isLastRev(rev, sensorType) {
      return this.sensorRevs[sensorType].indexOf(rev) === this.sensorRevs[sensorType].length - 1;
    },
    /* Ritorna true se il sensore con ID passato è selezionato */
    isSensorSelected(devID) {
      return this.getSelectedAvailableSensors.includes(devID);
    },
    /* Fetcha la lista di sensori disponibili (dopo delay ms), seleziona il primo e fetcha i dettagli di questo */
    fetchList(delay) {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          //this.fetchResults({ query: this.search });
          this.apiLoading = true;
          await this.fetchAvailableDevices({ type: this.type, eui: this.search, rev: this.rev, region: this.region });
          // Se avevo un solo sensore selezionato, garantisco di selezionarne un altro
          if (this.getSelectedAvailableSensors.length === 1) {
            await this.selectFirstAvailableSensor();
          }
          this.apiLoading = false;
          this.awaitingSearch = false;
        }, delay); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    /* Seleziona un sensore dato il suo ID e ne fetcha i dettagli */
    /* async selectSensor(devID) {
      this.selectedSensorID = devID;
      this.apiLoading = true;
      await this.fetchAvailableSensorDetails(this.selectedSensorID);
      this.apiLoading = false;
    }, */
    /* Seleziona il primo sensore disponibile e ne fetcha i dettagli */
    async selectFirstAvailableSensor() {
      const sensorIdToSelect = (this.getAvailableDevices.sensors[0] || {})._id;
      if (sensorIdToSelect) {
        this.apiLoading = true;
        this.setSelectedAvailableSensors([sensorIdToSelect]);
        await this.fetchAvailableSensorDetails(sensorIdToSelect);
        this.apiLoading = false;
      } else {
        this.setSelectedAvailableSensors([]);
      }
    },
    /* Assegna un sensore alla struttura selezionata */
    async assignSensor() {
      this.apiLoading = true;
      await this.assignSensorsToStructure({
        sensorIDs: this.getSelectedAvailableSensors,
        bridgeSpans: this.bridgeSpan !== null ? [this.bridgeSpan] : null,
        bridgeSides: this.bridgeSide !== null ? [this.bridgeSide] : null,
      });
      await this.fetchStructureData();
      this.apiLoading = false;
      this.$emit("close");
    },
    handleSensorItemClick(e, sensorID) {
      if (e.shiftKey && !this.singleSelect) {
        // Sto pigiando shift
        const alreadySelectedSensors = this.getSelectedAvailableSensors;
        if (alreadySelectedSensors.includes(sensorID)) {
          this.setSelectedAvailableSensors(this.getSelectedAvailableSensors.filter((sn) => sn !== sensorID));
        } else {
          this.setSelectedAvailableSensors([...this.getSelectedAvailableSensors, sensorID]);
        }
        console.log("Pigiato shift", this.getSelectedAvailableSensors);
      } else {
        // Non sto pigiando shift
        this.setSelectedAvailableSensors([sensorID]);
      }
    },
  },
  computed: {
    ...mapGetters({
      getAvailableDevices: "admin/getAvailableDevices",
      getAvailableSensorDetails: "admin/getAvailableSensorDetails",
      getSelectedAvailableSensors: "admin/getSelectedAvailableSensors",
    }),
  },
  watch: {
    async getSelectedAvailableSensors(newSensors) {
      if (newSensors && newSensors.length === 1) {
        this.apiLoading = true;
        await this.fetchAvailableSensorDetails(newSensors[0]);
        this.apiLoading = false;
      }
    },
    search() {
      // Quando esegue la ricerca con EUI
      this.fetchList(1000);
    },
    type(newType) {
      // Quando cambia il tipo carico l'ultima revisione disponibile
      this.rev = this.sensorRevs[newType][this.sensorRevs[newType].length - 1];
      this.fetchList(0);
      // Se cambio tipo deseleziono tutto
      this.setSelectedAvailableSensors([]);
    },
    rev() {
      // Quando cambia la rev
      this.fetchList(0);
      //this.setSelectedAvailableSensors([]);
    },
    region() {
      // Quando cambia la region
      this.fetchList(0);
    },
  },
  mounted() {
    // All'avvio init
    this.apiLoading = true;
    this.type = this.initalType || "deck";
    this.rev = "2.0";
    this.region = "EU";
    this.fetchList(0);
  },
};
</script>
<style scoped>
p,
label {
  font-size: 14px;
  margin: 8px;
}
h2 {
  font-size: 16px;
  margin-bottom: 10px;
}
#root {
  display: grid;
  grid-template-rows: min-content 10fr;
  min-height: 300px;
}
.form-field {
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
}
.form-field > button {
  margin: 0 55px 0 95px;
  width: 100%;
}
label {
  color: white;
  /* padding: 14px; */
  text-align: left;
  min-width: 80px;
}
select {
  margin-left: 0;
  height: 35px;
  width: 220px;
}
input[type="number"],
input[type="text"] {
  width: calc(220px - 0.8rem);
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  background-color: rgb(32, 34, 47) !important;
  border: none;
  padding: 0.4rem;
  color: white;
  font-size: 0.9em;
  outline: none !important;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 24px;
}
input[type="number"]:disabled.valid-field,
input[type="text"]:disabled.valid-field {
  border: none;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}
input[type="number"],
input[type="text"]:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.sensor-form {
  padding: 20px;
  grid-column: 1;
  grid-row: 1;
  text-align: left;
  background-color: rgb(80, 84, 105);
  border: solid 2px rgba(0, 0, 0, 0.4);
}

.sensor-list {
  padding: 20px;
  grid-column: 2;
  grid-row: 1/3;
  min-width: 380px;
  background-color: rgb(80, 84, 105);
  text-align: left;
  padding-left: 20px;
  border: solid 2px rgba(0, 0, 0, 0.4);
}

.sensor-details {
  padding: 20px;
  min-width: 370px;
  background-color: rgb(80, 84, 105);
  border: solid 2px rgba(0, 0, 0, 0.4);
  grid-column: 1;
  grid-row: 2;
  text-align: left;
}

.bottom-controls {
  grid-column: 1/3;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.bottom-controls > button {
  margin: 12px 0;
  padding: 8px;
}

.green-bg {
  background-color: green;
}

.empty-container {
  min-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.bright-container {
  background-color: rgb(80, 84, 105);
}

.strong {
  font-weight: 600;
  font-size: inherit;
}

.list-wrapper {
  background-color: rgb(45, 48, 65);
  padding: 12px;
  border-radius: 5px;
  height: 400px;
  overflow-y: scroll;
}

.sensor-item {
  background-color: rgba(0, 0, 0, 0.4);
  border: solid 1px white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 2px 0;
  font-weight: 500;
}

.selected-item {
  background-color: rgb(47, 112, 177);
}

.sensor-item > p:first-child {
  font-weight: 800;
}
</style>
