<i18n>
{
  "en": {
    "edit": "Edit",
    "undo": "Undo",
    "save": "Save",
    "editImage": "Edit image",
    "accelerometers": "Accelerometers",
    "deck": "Deck",
    "addSensor": "Add",
    "editSensor": "Edit Sensor",
    "fullView": "Expanded view",
    "dragSensors": "Drag all available sensors into the monitored structure image",
    "imgNotFound": "Monitored structure image not found.",
    "addImg": "Add image",
    "deck": "Deck",
    "tiltmeter": "Tilt.",
    "accelerometer": "Acc.",
    "analogNode": "Node",
    "crackmeter": "Crack",
    "vibrometer": "Vibrometer",
    "ppv": "PPV"
  },
  "it": {
    "edit": "Modifica",
    "undo": "Annulla",
    "save": "Salva",
    "editImage": "Modifica immagine",
    "accelerometers": "Accelerometri",
    "deck": "Deck",
    "addSensor": "Aggiungi sensore",
    "editSensor": "Modifica sensore",
    "fullView": "Visualizzazione espansa",
    "dragSensors": "Trascina tutti i sensori disponibili nell'immagine della struttura monitorata",
    "imgNotFound": "Immagine della struttura monitorata non trovata.",
    "addImg": "Aggiungi immagine",
    "deck": "Deck",
    "tiltmeter": "Inclin.",
    "accelerometer": "Acc.",
    "analogNode": "Nodo",
    "crackmeter": "Crack",
    "vibrometer": "Vibrometro",
    "ppv": "PPV"
  }
}
</i18n>

<template>
  <div>
    <!-- <balonTemplate id="baloon" :arrowDirection="'top'" v-show="false" /> -->

    <!-- Loading -->
    <transition name="load">
      <LoadingLogo v-if="loadingCount > 0" />
    </transition>

    <!-- Modale modifica/aggiunta sensore (admin) -->
    <div class="generic-building">
      <SensorInfo
        v-if="showSensorEditing && this.selectedSensors[0]"
        @close="showSensorEditing = false"
        @delete-sensor="deleteSensor"
        @add-sensor="insertNewSensor"
        @update-sensor="updateSensor"
        @reset-sensor-stats="resetSensorStats"
        :sensor="this.selectedSensors[0]"
        :newType="'deck'"
        :generic="true"
        :sensors="tempSensors"
      />
      <DeviceSearch v-if="showSensorEditing && !this.selectedSensors[0]" @close="showSensorEditing = false" />

      <!-- Gruppi FDD (admin) -->
      <transition name="settings">
        <Groups v-if="showFDD" :structure="this.selectedStructure" @close-groups="showFDD = false" />
      </transition>

      <!-- Tips in overlay -->
      <div id="help-tip" class="over-info">
        <div style="display: flex;">
          <h2>{{ title || $t("message.deviceselect") }}</h2>
          <div>
            <img class="pointer" src="../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <StructBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
          </div>
        </div>
        <p class="first-block">{{ subTitle }}</p>

        <!-- Selezione tipo sensore/i -->
        <div id="type-select">
          <button class="rounded-button" @click="typeSelect('deck')" :class="[actualTypes.includes('deck') ? 'active' : '']" :disabled="!availableTypes.includes('deck')">{{ $t("deck") }}</button>
          <button class="rounded-button" @click="typeSelect('tiltmeter')" :class="[actualTypes.includes('tiltmeter') ? 'active' : '']" :disabled="!availableTypes.includes('tiltmeter')">
            {{ $t("tiltmeter") }}
          </button>
          <button class="rounded-button" @click="typeSelect('accelerometer')" :class="[actualTypes.includes('accelerometer') ? 'active' : '']" :disabled="!availableTypes.includes('accelerometer')">
            {{ $t("accelerometer") }}
          </button>
          <button class="rounded-button" @click="typeSelect('analog-node')" :class="[actualTypes.includes('analog-node') ? 'active' : '']" :disabled="!availableTypes.includes('analog-node')">
            {{ $t("analogNode") }}
          </button>
          <button class="rounded-button" @click="typeSelect('crackmeter')" :class="[actualTypes.includes('crackmeter') ? 'active' : '']" :disabled="!availableTypes.includes('crackmeter')">
            {{ $t("crackmeter") }}
          </button>
          <button class="rounded-button" @click="typeSelect('vibrometer')" :class="[actualTypes.includes('vibrometer') ? 'active' : '']" :disabled="!availableTypes.includes('vibrometer')">
            {{ $t("vibrometer") }}
          </button>
        </div>
        <div id="expanded-switch">
          <p>{{ $t("fullView") }}</p>
          <label class="switch"
            ><input type="checkbox" v-model="expandedView" />
            <span class="slider round"></span>
          </label>
        </div>

        <!-- Controlli -->
        <div class="extra-control" :class="{ 'extra-control-down': adminMode && editMode }">
          <!-- Admin Settings -->
          <button v-if="editMode && adminMode" class="rounded-button" @click="addSensor">
            {{ selectedSensors.length > 0 ? $t("editSensor") : $t("addSensor") }}
          </button>
          <button v-if="editMode && adminMode" class="rounded-button" @click="showFDD = true" :disabled="!tempSensors.some((s) => s.type === 'accelerometer' || s.type === 'deck')">FDD</button>

          <!-- User settings -->
          <button v-if="!editMode && !isSubUser" class="rounded-button" @click="editMode = true">{{ $t("edit") }}</button>
          <button v-if="editMode" class="rounded-button" @click="showImgUpload = !showImgUpload">{{ $t("editImage") }}</button>
          <button v-if="editMode" class="rounded-button" @click="init">{{ $t("undo") }}</button>
          <button v-if="editMode" class="rounded-button" @click="saveSensorsPosition" :disabled="!validToSave">{{ $t("save") }}</button>
        </div>
      </div>

      <!-- Main Drop area -->
      <div id="drop-area" class="dropp">
        <img v-if="selectedStructure.images && selectedStructure.images.length > 0" class="map-img noselect-hard" :src="`data:${selectedStructure.images[0].image.contentType};base64,${selectedStructure.images[0].image.buffer}`" />
        <div v-else class="overlay">
          <h1>{{ $t("imgNotFound") }}</h1>
          <button class="rounded-button" @click="showImgUpload = !showImgUpload">{{ $t("addImg") }}</button>
        </div>

        <!-- Oggeto sensore posizionato -->
        <div
          :class="[
            !expandedView ? 'dropped-circle' : '',
            sensor.position && sensor.position.y && sensor.position.x ? 'dropped' : '',
            checkIfSelected(sensor) ? 'selected' : '',
            'sensor shadow noselect-hard',
          ]"
          v-for="(sensor, index) in tempSensors"
          :key="sensor.eui"
          :id="sensor.eui"
          v-show="checkIfAlreadyPositioned(sensor) && actualTypes.includes(sensor.type)"
          :style="{
            top: sensor && sensor.position && sensor.position.y ? sensor.position.y + '%' : 30 * (index % 10) + 'px',
            left: sensor && sensor.position && sensor.position.x ? sensor.position.x + '%' : parseInt(index / 10) * 60 + 20 + 'px',
            borderColor: checkIfOnline(sensor) ? '' : 'red',
            backgroundColor: !expandedView ? (checkIfOnline(sensor) ? 'green' : 'red') : 'white',
          }"
          @click="selectSensor(sensor)"
        >
          <!-- <img class="sensor-status" v-if="!checkIfOnline(sensor)" src="../../../public/assets/icons/wifi_off.svg" alt="offline" /> -->
          <span class="sensor-dot" :style="{ backgroundColor: getSensorColor(sensor) }" v-if="expandedView"></span>
          <p class="sensor_type" :style="{ marginLeft: checkIfOnline(sensor) ? '4px' : '2px' }">
            {{ getTypeName(sensor.type, false) }}
          </p>
          <p class="sensor_eui">
            {{ sensor.eui.substr(sensor.eui.length - 5) }}
          </p>
        </div>
      </div>

      <!-- Modulo laterale nuovi sensori -->
      <div class="noselect-hard" id="new-sensors-module" v-if="newSensors && newSensors.length > 0">
        <div id="new-sensors-heading">
          <p>{{ $t("dragSensors") }}</p>
        </div>
        <div id="new-sensors-container" ref="newSensorsContainer">
          <div
            v-for="(newSensor, index) in newSensors"
            :key="newSensor.eui"
            :style="{ top: 45 * index + 16 + 'px' }"
            class="sensor new-sensor not-yet-dropped"
            :class="[checkIfSelected(newSensor) ? 'new_sensor_selected' : '']"
            :id="newSensor.eui"
            @click="adminMode ? selectSensor(newSensor) : null"
          >
            <img src="../../../public/assets/icons/drag.svg" alt="drag" />
            <p>
              {{ newSensor.type.charAt(0).toUpperCase() + newSensor.type.slice(1) + " " }} <b>{{ newSensor.eui.substr(newSensor.eui.length - 5) }}</b>
            </p>
          </div>
        </div>
      </div>

      <!-- Modulo laterale contenuto aggiuntivo (slot) -->
      <div id="side-module" v-else>
        <slot name="side-content"></slot>
      </div>

      <!-- Modal Img Upload -->
      <transition name="settings">
        <ModalImgUpload
          v-if="showImgUpload"
          @close="showImgUpload = false"
          :exsistingImg="selectedStructure.images && selectedStructure.images[0] ? `data:image/png;base64,${selectedStructure.images[0].image.buffer}` : null"
          @setNewImage="setNewImage"
        />
      </transition>
    </div>
  </div>
</template>
<script>
/* 
  Modulo che gestisce la visualizzazione e l'editing dell'immagine strutture.

  Props: 
    adminMode: mostra i comandi avanzati
    singleTypeSelect: permette di selezionare un tipo di sensore per volta
    multiSensorSelect: permette di selezionare più sensori contemporaneamente

  Eventi:
    Admin:
      delete-sensor
      add-sensor
      update-sensor
      reset-stats-sensor
    Standard:
      sensor-selected (obj)
      refresh
*/
import { mapGetters, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import SensorInfo from "../admin/modal/SensorInfo.vue";
import Groups from "../admin/modal/GroupsV2.vue";
import sensorsUtils from "../../helpers/sensors";
import ModalImgUpload from "../modules_V2/ModalImgUpload.vue";
import LoadingLogo from "../../components/ui/LoadingLogo.vue";
import StructBalloon from "../tutorial/balloons/StructBl.vue";
import DeviceSearch from "../ui/DeviceSearch.vue";
import api from "../../services/api";

export default {
  name: "image-building",
  components: {
    Groups,
    SensorInfo,
    ModalImgUpload,
    LoadingLogo,
    StructBalloon,
    DeviceSearch
  },
  props: {
    title: String,
    subTitle: String,
    selectedStructure: Object,
    adminMode: Boolean,
    singleTypeSelect: Boolean,
    multiSensorSelect: Boolean, // Selezione multipla sensori
    typesFilter: Array, // Mi prendo solo i sensori di questi tipi
  },
  data() {
    return {
      showImgUpload: false,
      showSensorEditing: false,
      editMode: false,
      isInfoVisible: false,
      expandedView: null,
      selectedSensors: [],
      tempSensors: null,
      actualTypes: [],
      availableTypes: [],
      types: ["deck", "tiltmeter", "accelerometer", "analog-node", "crackmeter", "vibrometer"],
      image: null,
      showFDDGroupsDeck: false,
      showFDDGroupsAcc: false,
      showFDD: false,
      loadingCount: 0,
      refreshingInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedUser: "admin/getSelectedUser",
      getUserType: "user/getUserType"
    }),
    // Sensori non ancora posizionati e coerenti con i tipi selezionati
    newSensors() {
      return this.tempSensors ? this.tempSensors.filter((sn) => !this.checkIfAlreadyPositioned(sn) && this.actualTypes.includes(sn.type)) : [];
    },
    validToSave() {
      return this.newSensors.length === 0;
    },
    isSubUser(){
      return this.getUserType && this.getUserType === 'sub-user';
    }
  },
  methods: {
    ...mapActions({
      fetchStructureData: "structure/fetchStructureData",
      fetchStructuresAdmin: "admin/fetchStructures",
      selectStructureAdmin: "admin/selectStructure",
      editSensorPosition: "admin/editSensorPosition",
    }),
    // Refresha gli oggetti sensori
    async refreshSensorsStats() {
      if (!this.editMode) {
        const sensorsFromApi = await api.getSensorsSettings(this.selectedStructure._id);
        this.tempSensors = JSON.parse(JSON.stringify(sensorsFromApi));
        console.log("Sensors refresh");
      }
    },
    // Crafta il file dell'immagine
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // Handling del cambio immagine
    async setNewImage(newImage) {
      let userID;
      const structureID = this.selectedStructure._id;
      this.loadingCount += 1;
      if (this.adminMode) {
        userID = this.getSelectedUser._id;
        if (this.selectedStructure.images && this.selectedStructure.images[0]) {
          await api.deleteImageFromStructure(userID, this.selectedStructure._id, this.selectedStructure.images[0]._id);
        }
        await api.addImageToStructure(userID, this.selectedStructure._id, this.dataURLtoFile(newImage, "newImage.jpeg"));
        await this.fetchStructuresAdmin(userID);
        await this.selectStructureAdmin(structureID);
      } else {
        userID = localStorage.getItem("userID");
        if (this.selectedStructure.images && this.selectedStructure.images[0]) {
          await api.deleteImageFromStructureUser(userID, this.selectedStructure._id, this.selectedStructure.images[0]._id);
        }
        await api.addImageToStructureUser(userID, this.selectedStructure._id, this.dataURLtoFile(newImage, "newImage.jpeg"));
        await this.fetchStructureData();
        await this.init();
      }
      this.loadingCount -= 1;
    },
    // Annulla
    undo() {
      this.tempSensors = JSON.parse(JSON.stringify(this.selectedStructure.sensors));
    },
    // Selezione sensore/i
    selectSensor(sensor) {
      if (this.checkIfAlreadyPositioned(sensor)) {
        if (this.adminMode && this.selectedSensors.length > 0) {
          this.selectedSensors = [];
        } else {
          if (this.multiSensorSelect) {
            const alreadySelected = this.selectedSensors.find((s) => s.eui === sensor.eui);
            if (alreadySelected) {
              this.selectedSensors = this.selectedSensors.filter((s) => s.eui != sensor.eui);
            } else {
              this.selectedSensors.push(sensor);
            }
            this.$emit("sensors-selected", this.selectedSensors);
          } else {
            this.selectedSensors = [sensor];
            this.$emit("sensor-selected", this.selectedSensors[0]);
          }
        }
      } else if (this.adminMode) {
        if (this.selectedSensors.length > 0) {
          this.selectedSensors = [];
        } else {
          this.selectedSensors = [sensor];
        }
      }
    },
    getSensorColor(sensor) {
      let color;
      if (this.checkIfOnline(sensor)) {
        color = "green";
      } else {
        color = "red";
      }
      return color;
    },
    // Admin: mostra modale apposito
    addSensor() {
      this.showSensorEditing = !this.showSensorEditing;
    },
    // Admin
    deleteSensor(sensor) {
      this.$emit("delete-sensor", sensor);
    },
    // Admin
    insertNewSensor(sensor) {
      this.$emit("add-sensor", sensor);
    },
    // Admin
    updateSensor(eui, sensor) {
      let sensorObject = {};
      sensorObject.eui = eui;
      sensorObject.sensorInfo = sensor;
      this.$emit("update-sensor", sensorObject);
    },
    // Admin
    resetSensorStats(sensor) {
      this.$emit("reset-stats-sensor", sensor);
    },
    checkIfAlreadyPositioned(sensor) {
      return sensor.position && sensor.position.x != null && sensor.position.x != undefined && sensor.position.y != null && sensor.position.y != undefined;
    },
    checkIfSelected(sensor) {
      return this.selectedSensors.length > 0 && this.selectedSensors.find((s) => s.eui === sensor.eui);
    },
    checkIfOnline: sensorsUtils.checkIfOnline,
    getTypeName(sensorType, short) {
      switch (sensorType) {
        case "deck":
          return short ? "Dk" : "Deck";
        case "tiltmeter":
          return short ? "Tl" : "Tilt";
        case "accelerometer":
          return short ? "Ax" : "Axel";
        case "analog-node":
          return short ? "An" : "Node";
        case "crackmeter":
          return short ? "Cr" : "Crack";
        case "vibrometer":
          return short ? "Vib" : "Vibrometer";
      }
    },
    // Handling selezione tipo
    typeSelect(type) {
      if (this.actualTypes.includes(type)) {
        if (this.actualTypes.length > 1) {
          // Se viene selezionato un tipo ed è diverso da quello del sensore attualmente selezionato, seleziono un nuovo sensore
          this.actualTypes = this.actualTypes.filter((e) => e != type);
          for (let i = 0; i < this.actualTypes.length; i++) {
            if (type === this.selectedSensors[0].type) {
              let foundSensor = this.tempSensors.find((s) => s.type === this.actualTypes[i]);
              if (foundSensor) {
                if (!this.multiSensorSelect) {
                  // Seleziono sensore SINGOLO
                  this.selectedSensors[0] = foundSensor;
                  this.$emit("sensor-selected", this.selectedSensors[0]);
                } else {
                  // Seleziono sensori MULTIPLI
                  this.$emit(
                    "sensors-selected",
                    this.tempSensors.filter((s) => s.type === this.actualTypes[i])
                  );
                }
                break;
              } else {
                this.selectedSensors = [];
              }
            }
          }
        }
      } else {
        if (this.singleTypeSelect) {
          this.actualTypes = [];
        }
        this.actualTypes.push(type);
      }
      if (this.newSensors && this.newSensors.length > 0) {
        setTimeout(this.handleDragAndDrop, 100);
      }
    },
    /* Salvataggio posizione sensori con API */
    saveSensorsPosition() {
      if (this.tempSensors.length > 0) {
        this.loadingCount += 1;
        const newPositions = this.tempSensors
          .filter((s) => s.position && s.position.x && s.position.y)
          .map((s) => ({
            eui: s.eui,
            position: {
              ...s.position,
              x: parseFloat(s.position.x),
              y: parseFloat(s.position.y),
            },
          }));
        if (this.adminMode === false) {
          api
            .saveSensorsPosition({ sensors: newPositions })
            .then(() => {
              this.$emit("refresh");
            })
            .finally(() => {
              this.loadingCount -= 1;
            });
          this.editMode = false;
        } else {
          this.editSensorPosition(newPositions)
            .then(() => {
              this.$emit("refresh");
            })
            .finally(() => {
              this.loadingCount -= 1;
            });
        }
      }
    },
    /* Utility interne per il drag and drop */
    setDroppedProps(eui, x, y) {
      for (let i = 0; i < this.tempSensors.length; i++) {
        if (this.tempSensors[i].eui === eui) {
          this.tempSensors[i].position = this.tempSensors[i].position || {};
          this.tempSensors[i].position["x"] = parseFloat(x);
          this.tempSensors[i].position["y"] = parseFloat(y);
        }
      }
    },
    whileDragging() {
      $("#new-sensors-container").css({
        overflowY: "inherit",
      });
    },
    stoppedDragging() {
      $("#new-sensors-container").css({
        overflowY: "scroll",
      });
    },
    handleDragAndDrop() {
      var vm = this;
      $(".sensor").draggable({
        cursor: "crosshair",
        revert: "invalid",
        containment: "#drop-area #new-sensors-container",
        drag: function() {
          vm.whileDragging();
        },
        stop: function(event) {
          vm.stoppedDragging();
          // Variabili da settare
          let top;
          let left;
          // E' effettivamente droppato sulla Drop Area?
          const isDroppedOnDropArea = $(event.target.offsetParent).attr("id") === "drop-area";
          // Posizione assolute sensore in questo istante
          const sensorPositionTop = $(this).position().top;
          const sensorPositionLeft = $(this).position().left;
          // Dimensioni area di drop
          const dropAreaWidth = $("#drop-area").width();
          const dropAreaHeight = $("#drop-area").height();
          // Offset area di drop
          const dropAreaOffsetTop = $("#drop-area").offset().top;
          const dropAreaOffsetLeft = $("#drop-area").offset().left;

          if (!isDroppedOnDropArea) {
            // Spostato altrove
            console.log("Dummy move");
          } else if (!$(this).hasClass("first-move")) {
            // Era già positionato
            left = parseFloat(100 * parseFloat(sensorPositionLeft / parseFloat(dropAreaWidth /*  - 10 */))) + "%";
            top = parseFloat(100 * parseFloat(sensorPositionTop / parseFloat(dropAreaHeight))) + "%";
            vm.setDroppedProps($(this)[0].id, left, top);
            //console.log("Vecchio", $(this)[0].id, left, top);
          } else {
            const newSensorsOffsetTop = $("#new-sensors-container").offset().top;
            const newSensorsOffsetLeft = $("#new-sensors-container").offset().left;
            // Nuovo appena posizionato
            left = parseFloat(100 * parseFloat((sensorPositionLeft - (dropAreaOffsetLeft - newSensorsOffsetLeft)) / parseFloat(dropAreaWidth))) + "%";
            top = parseFloat(100 * parseFloat((sensorPositionTop - (dropAreaOffsetTop - newSensorsOffsetTop)) / parseFloat(dropAreaHeight))) + "%";
            vm.setDroppedProps($(this)[0].id, left, top);
            //console.log("Nuovo", $(this)[0].id, left, top);
          }
        },
        snapMode: "both",
        snap: "#drop-area",
        grid: [5, 5],
        snapTolerance: 1,
      }),
        $("#drop-area").droppable({
          accept: ".sensor",
          // eslint-disable-next-line no-unused-vars
          drop: function(event, ui) {
            var dropped = ui.draggable;
            if ($(dropped).hasClass("not-yet-dropped")) {
              // Nuovo appena posizionato
              console.log("Dropped to drop area");
              $(dropped)
                .removeClass("not-yet-dropped")
                .removeClass("new-sensor")
                .detach()
                .appendTo($("#drop-area"))
                .addClass("first-move");
              vm.setDroppedProps(dropped[0].id, "20%", "20%");
            }
          },
          // eslint-disable-next-line no-unused-vars
          over: function(event, elem) {
            $(this).addClass("over");
          },
          // eslint-disable-next-line no-unused-vars
          out: function(event, elem) {
            $(this).removeClass("over");
          },
        });
    },
    /* Inizializzazione modulo */
    init() {
      console.log("Generic init.");
      this.tempSensors = JSON.parse(
        JSON.stringify(
          this.selectedStructure.sensors.map((sn) => {
            return {
              ...sn,
              position: {
                x: sn.position.x === undefined ? null : sn.position.x,
                y: sn.position.y === undefined ? null : sn.position.y,
              },
            };
          })
        )
      );
      const alreadySelected = this.selectedSensors.length > 0 && this.tempSensors.find((s) => s.eui === this.selectedSensors[0].eui);
      // Se ho dei sensori ancora da posizionare entro in editMode automaticamente
      const anyNewSensor = this.tempSensors.some((s) => !this.checkIfAlreadyPositioned(s));
      this.editMode = anyNewSensor;

      // Pre-filtro i tipi
      if (this.typesFilter) {
        this.types = this.typesFilter;
      }
      // Inietto tipi di sensori disponibili
      for (let i = 0; i < this.types.length; i++) {
        let foundSensor = this.tempSensors.find((s) => s.type === this.types[i]);
        if (foundSensor) {
          this.availableTypes.push(foundSensor.type);
          this.actualTypes.push(foundSensor.type);
        }
      }
      if (!this.multiSensorSelect) {
        // Seleziono sensore iniziale SINGOLO
        for (let i = 0; i < this.types.length && !alreadySelected; i++) {
          let foundSensor = this.tempSensors.find((s) => s.type === this.types[i]);
          if (foundSensor && this.selectedStructure.images && this.selectedStructure.images[0]) {
            this.selectedSensors[0] = foundSensor;
            break;
          } else {
            this.selectedSensors = [];
          }
        }
        if (!anyNewSensor && !alreadySelected) {
          this.$emit("sensor-selected", this.selectedSensors[0]);
        }
      } else {
        // Seleziono sensori MULTIPLI
        for (let i = 0; i < this.types.length; i++) {
          let foundSensors = this.tempSensors.filter((s) => s.type === this.types[i]);
          if (foundSensors.length > 0 && this.selectedStructure.images && this.selectedStructure.images[0]) {
            this.selectedSensors = foundSensors;
          } else {
            this.selectedSensors = [];
          }
        }
        if (!anyNewSensor && !alreadySelected) {
          this.$emit("sensors-selected", this.selectedSensors);
        }
      }

      // Seleziono sensore e gestisco drag and drop

      this.$nextTick(() => {
        this.handleDragAndDrop();
        $(".sensor").draggable({ disabled: !this.editMode || Boolean(!(this.selectedStructure.images && this.selectedStructure.images[0])) });
      });
    },
  },
  mounted() {
    this.init();
    const expViewSet = localStorage.getItem("generic-expanded-view");
    // Se è null -> false; altrimenti se è "true" -> true ovvero false.
    this.expandedView = expViewSet === null ? true : expViewSet === "true";
    if (!this.adminMode) {
      this.refreshingInterval = setInterval(this.refreshSensorsStats, 60000);
    }
  },
  beforeDestroy: function() {
    if (!this.adminMode) {
      clearInterval(this.refreshingInterval);
    }
  },
  watch: {
    editMode: function(newValue) {
      $(".sensor").draggable({ disabled: Boolean(!newValue) });
    },
    expandedView(newValue) {
      localStorage.setItem("generic-expanded-view", newValue);
    },
    selectedStructure: {
      deep: true,
      handler(newStructure, oldStructure) {
        if (this.adminMode || !this.editMode || !oldStructure || newStructure._id != oldStructure._id) {
          // Mi mappo i sensori precedentemente selezionati con gli EUI e poi li ri-seleziono per rispecchiare eventuali cambiamenti nell'obj
          const oldSelectedSensorsEUIS = this.selectedSensors.map((s) => s.eui);
          this.init(); // Init
          this.selectedSensors = newStructure.sensors.filter((s) => oldSelectedSensorsEUIS.includes(s.eui));
          // Selezione SINGOLA
          if (!this.multiSensorSelect) {
            if (oldSelectedSensorsEUIS.length > 0) {
              this.$emit("sensor-selected", this.selectedSensors[0]);
            }
          } else {
            // Selezione MULTIPLA
            if (oldSelectedSensorsEUIS.length > 0) {
              this.$emit("sensors-selected", this.selectedSensors);
            }
          }
        }
      },
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 1em;
}
#baloon {
  position: absolute;
  top: 180px;
  left: 200px;
  z-index: 12;
}
p {
  text-align: left;
  margin: 0;
}
header > h2 {
  font-weight: 400;
}
button:disabled {
  background-color: grey;
}
button.active {
  background-color: rgba(21, 146, 230, 1);
}
.generic-building {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  position: relative;
  -ms-grid-columns: 10fr auto;
  grid-template-columns: 10fr auto;
  background-color: rgb(45, 48, 65);
  width: 96%;
  min-width: 1200px;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.generic-building > *:nth-child(1) {
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 1;
}
.generic-building > *:nth-child(2) {
  grid-row: 1;
  -ms-grid-row: 1;
  grid-column: 2;
  -ms-grid-column: 2;
}

.sensor {
  background-color: green;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 5px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sensor-dot {
  height: 12px;
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  margin-left: 4px;
  background-color: unset;
  border-radius: 12px;
  display: inline-block;
  border: solid 2px white;
  z-index: 2;
}
/* .sensor-status {
  top: calc(25% - 6px);
  width: 16px;
  margin-left: 2px;
  background-color: unset;
  display: inline-block;
  z-index: 12;
} */
#drop-area {
  position: relative;
  -ms-grid-row: 2;
  grid-row: 2;
  -ms-grid-column: 1;
  grid-column: 1;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  align-self: end;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(29, 31, 41, 0.35);
  margin-top: 20px;
}

.overlay {
  background-color: rgb(14, 15, 19) !important;
  z-index: 8;
  border-radius: 10px;
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.overlay > button {
  margin-left: 20px;
}
.dropped {
  margin-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 25px;
  background-color: white;
  border: solid 3px white;
  border-bottom-left-radius: 2px;
  z-index: 2;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
}
.dropped:hover,
.selected {
  margin-top: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  min-width: 12px;
  max-width: fit-content;
  width: fit-content;
  z-index: 3;
}

.dropped > p {
  color: black;
  white-space: nowrap;
  margin-left: 6px;
}

/* .selected > p {
  color: rgb(24, 135, 222);
} */
.selected {
  border: solid 3px rgb(24, 135, 222) !important;
}
.sensor_eui {
  left: 40px;
  visibility: hidden;
  opacity: 0;
  font-size: 0;
  font-weight: 400;
  padding-right: 4px;
  -webkit-transition: font-size 0.15s ease;
  -o-transition: font-size 0.15s ease;
  transition: font-size 0.15s ease;
}
.sensor_type {
  font-size: 14px;
}
.dropped:hover > .sensor_eui,
.dropped:hover > .sensor_type {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
}
.selected > .sensor_eui,
.selected > .sensor_type {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
}
.dropped-circle {
  height: 16px;
  width: 16px;
  margin-top: 10px;
  transition: all 0.2s;
  border: solid 2px white !important;
}
.dropped-circle > img {
  visibility: hidden;
}
.dropped-circle:hover > img {
  visibility: visible;
}
.dropped-circle.selected {
  background-color: rgb(24, 135, 222) !important;
}
.dropped-circle:hover {
  background-color: white !important;
  height: 27px;
  margin-bottom: -2px;
}
.dropped-circle > .sensor_eui,
.dropped-circle > .sensor_type {
  visibility: hidden;
  font-size: 0px;
}
/* .dropped-circle :hover > .sensor_eui,
.dropped-circle :hover > .sensor_type {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
} */
.map-img {
  position: relative;
  left: 0;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
#help-tip {
  text-align: left;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  display: -ms-grid;
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  padding-right: 0;
  -ms-grid-row: 1;
  grid-row: 1;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-columns: auto 10fr;
  grid-template-columns: auto 10fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#help-tip > p {
  margin-bottom: 12px;
}
#expanded-switch {
  grid-column: 2;
}
.extra-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-row: 1/3;
  grid-row: 1/3;
  -ms-grid-column: 2;
  grid-column: 2;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  align-self: center;
  margin-left: 30px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.extra-control-down {
  -ms-grid-row: 4;
  grid-row: 4;
  -ms-grid-column: 1;
  grid-column: 1;
  margin-left: 0px;
  justify-content: space-between;
}
.extra-control button {
  margin-right: 16px;
}
.extra-control-down > button:last-child {
  margin-right: 0px;
}
#expanded-switch {
  display: flex;
  justify-self: flex-end;
  padding-right: 20px;
  align-items: center;
}
#expanded-switch > p {
  margin-right: 8px;
}
button:disabled {
  background-color: grey;
  cursor: default;
}
#type-select {
  -ms-grid-column: 1;
  grid-column: 1;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 100%;
  grid-template-rows: 100%;
  -ms-grid-columns: 2fr 20px 2fr 20px 2fr 20px 2fr 20px 2fr;
  grid-template-columns: repeat(6, 1.66fr);
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

#new-sensors-module {
  border: solid 2px white;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  width: 260px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgb(70, 74, 94) !important;
}
#side-module {
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#new-sensors-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
#new-sensors-heading {
  border-bottom: solid 2px white;
  padding: 10px;
}
.new-sensor {
  width: 240px;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 10fr;
  grid-template-columns: auto 10fr;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px;
  padding: 4px 6px;
  border-radius: 2px;
  background-color: rgb(40, 42, 58);
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  border-left: solid 3px white;
  border-right: solid 3px white;
  cursor: move;
}
.new_sensor_selected {
  background-color: rgb(24, 135, 222);
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(191, 191, 191);
  border-radius: 20px;
  height: 40px;
}
*::-webkit-scrollbar-track {
  margin-top: 70px;
  margin-bottom: 70px;
}
.pointer{
  margin-left: 6px;
}
</style>
